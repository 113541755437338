<template>
  <div class="fill-height interpretationArea ma-0" style="position: relative">
    <NavbarTest/>

    <div class="circleOne"></div>
    <!--    <div class="circlefour"></div>-->
    <div class="circleTwo"></div>
    <div class="circleThree"></div>
    <div class="interpretationContent  " :class="report == null && 'fill-height'">
      <div v-if="report == null" class="fill-height">
        <div class="d-flex flex-column justify-space-around text-center fill-height">
          <div>
            <v-progress-circular indeterminate color="#46b1a1"></v-progress-circular>
            <p class="mt-3 font-weight-bold">
              در حال آماده سازی تفسیر تست
            </p>
          </div>
        </div>
      </div>
      <div class="interpretationInner" v-else>
        <template v-if="targetTemplate">
          <component
              @getAiResponse="getAiData"
              :ai_response="ai_response" @registerOrTestPanel="registerOrTestPanel"
              :is="targetTemplate.component"
              :report="report"></component>
        </template>

      </div>
    </div>
    <div class="footer white">
      <div class="footerContent">
        <v-row>
          <v-col class="px-10" cols="12" lg="4">
            <div class="footerItem">
              <div class="footerItemTitle">
                حوزه‌های مشاوره
              </div>
              <v-divider class="my-3" style="background: #707070"></v-divider>
              <div class="footerItemContent">
                <div class="d-flex justify-space-between">
                  <div>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D8%AF%D8%B1%D9%85%D8%A7%D9%86-%D8%A7%D9%81%D8%B3%D8%B1%D8%AF%DA%AF%DB%8C/">درمان
                        افسردگی</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D8%AF%D8%B1%D9%85%D8%A7%D9%86-%D8%A7%D8%B6%D8%B7%D8%B1%D8%A7%D8%A8/">درمان
                        اضطراب و استرس</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%d8%b7%d9%84%d8%a7%d9%82/">مشاوره
                        طلاق</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D9%88%D8%B3%D9%88%D8%A7%D8%B3-%D9%81%DA%A9%D8%B1%DB%8C/">درمان
                        وسواس فکری</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%D8%A7%D8%B2%D8%AF%D9%88%D8%A7%D8%AC/">مشاوره
                        ازدواج</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D8%B2%D9%88%D8%AC-%D8%AF%D8%B1%D9%85%D8%A7%D9%86%DB%8C/">زوج
                        درمانی</a>
                    </p>
                  </div>
                  <div>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%D8%AC%D9%86%D8%B3%DB%8C/">مشاوره
                        جنسی</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%D9%81%D8%B1%D8%AF%DB%8C-%D8%A7%DB%8C%D8%B1%D8%A7%D9%86%DB%8C%D8%A7%D9%86-%D8%AE%D8%A7%D8%B1%D8%AC-%DA%A9%D8%B4%D9%88%D8%B1/">مشاوره
                        فردی</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%D8%AE%DB%8C%D8%A7%D9%86%D8%AA/">مشاوره
                        خیانت</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%D8%AE%D8%A7%D9%86%D9%88%D8%A7%D8%AF%D9%87/">مشاوره
                        خانواده</a>
                    </p>
                    <p>
                      <a class="linkExternal"
                         href="https://simiaroom.com/counseling/%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%DA%A9%D9%88%D8%AF%DA%A9-%D9%86%D9%88%D8%AC%D9%88%D8%A7%D9%86-%D8%A7%DB%8C%D8%B1%D8%A7%D9%86%DB%8C%D8%A7%D9%86-%D8%AE%D8%A7%D8%B1%D8%AC-%DA%A9%D8%B4%D9%88%D8%B1/">مشاوره
                        کودک و نوجوان</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="px-10" lg="4">
            <div class="footerItem">
              <div class="footerItemTitle">
                تماس
              </div>
              <v-divider class="my-3" style="background: #707070"></v-divider>
              <div class="footerItemContent" style="font-size: 14px">
                <p>
                  <span>
                    Email
                  </span>
                  <span style="color: #000 !important;">
                    info@simiaroom.com
                  </span>
                </p>
                <p class="mt-5">
                  <span>
                    Whatsapp
                  </span>
                  <span style="color: #000 !important;">
                    00989302672054
                  </span>
                </p>
                <p class="mt-5">
                  <span>
                    Telegram
                  </span>
                  <span style="color: #000 !important;">
                    @simiaroom
                  </span>
                </p>
              </div>

            </div>
          </v-col>
          <v-col cols="12" class="px-10" lg="4">
            <div class="footerItem">
              <div class="footerItemTitle">
                ســایر
              </div>
              <v-divider class="my-3" style="background: #707070"></v-divider>
              <div class="footerItemContent" style="font-size: 14px">
                <p>
                  <a href="https://simiaroom.com/counselor/" class="linkExternal">
                    مشاوران سیمیاروم
                  </a>
                </p>
                <p>
                  <a href="https://simiaroom.com/blog" class="linkExternal">
                    بـــلاگ
                  </a>
                </p>
                <p>
                  <a href="https://simiaroom.com/%D8%AF%D8%B1%D8%A8%D8%A7%D8%B1%D9%87-%D9%85%D8%A7/"
                     class="linkExternal">
                    درباره
                  </a>
                </p>
              </div>
              <div class="footerItemTitle mt-10">
                ما را دنبال کنید
              </div>
              <v-divider style="background: #707070"></v-divider>
              <div class="footerItemContent">
                <div class="d-flex justify-space-between">
                  <div class="linkExternal">
                    <a href="https://www.youtube.com/simiaroom">
                      <v-icon>mdi-youtube</v-icon>
                    </a>
                  </div>
                  <div class="linkExternal">
                    <a href="https://twitter.com/simiaroom">
                      <v-icon>mdi-twitter</v-icon>
                    </a>
                  </div>
                  <div class="linkExternal">
                    <a href="https://www.facebook.com/simiatalk">
                      <v-icon>mdi-facebook</v-icon>
                    </a>
                  </div>
                  <div class="linkExternal">
                    <a href="https://www.instagram.com/simiaroomcom/">
                      <v-icon>mdi-instagram</v-icon>
                    </a>
                  </div>
                  <div class="linkExternal">
                    <a href="https://www.linkedin.com/company/simiaroom/">
                      <v-icon>mdi-linkedin</v-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="footerSubTitle mt-10">
          <div class="d-flex justify-space-between  align-center" :class="$vuetify.breakpoint.mdAndDown && 'flex-wrap'">
            <div class="pt-8 text-center  flex-grow-1">
              made with
              <v-icon small>mdi-heart-outline</v-icon>
              at {{ getPlatformInfo('name') }}
            </div>
            <div class="pt-8 text-center flex-grow-1">
              copyright 2022 {{ getDomainPlatform() }} All Rights Reserved ©
            </div>
            <div class="text-center flex-grow-1">
              <div class="d-flex flex-column justify-start ">
                <div>
                  <v-btn text color="#46b1a1" @click="goToTop">
                    بازگشت به بالا
                  </v-btn>
                </div>
                <div>
                  <a href="https://simiaroom.com/%D8%B4%D8%B1%D8%A7%DB%8C%D8%B7-%D8%B6%D9%88%D8%A7%D8%A8%D8%B7-%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87/"
                     target="_blank">
                    <v-btn color="termsBtn" elevation="0">
                      شرایط و قوانین
                    </v-btn>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import BeckInterpretation from "@/views/indexes/tests/interpretation/beckInterpretation";
import SclInterpretation from "@/views/indexes/tests/interpretation/sclInterpretation";
import Tpcinterpretation from "@/views/indexes/tests/interpretation/tpcinterpretation";
import StlsInterpretation from "@/views/indexes/tests/interpretation/stlsInterpretation";
import SexualFunctionInterpretation from "@/views/indexes/tests/interpretation/SexualFunctionInterpretation";
import GeneralInterpretation from "@/views/indexes/tests/interpretation/generalInterpretation";
import QolInterpretation from "@/views/indexes/tests/interpretation/QolInterpretation";
import BodyShamingInterpretation from "@/views/indexes/tests/interpretation/bodyShamingInterpretation";
import DisappointmentBeckInterpretation from "@/views/indexes/tests/interpretation/DisappointmentBeckInterpretation";
import NeoInterpretation from "@/views/indexes/tests/interpretation/NeoInterpretation";
import {mapGetters} from "vuex";
import MentalReadinessForChangeInterpretation
  from "@/views/indexes/tests/interpretation/MentalReadinessForChangeInterpretation";
import YEMSQPlusInterpretation from "@/views/indexes/tests/interpretation/YEMSQPlusInterpretation";
import {EventBus} from "@/event-bus";
import NavbarTest from "@/components/Test/NavbarTest";
import AdvanceTestTemplate from "@/views/indexes/tests/interpretation/advanceTestTemplate";
import axios from "axios";

export default {
  components: {
    AdvanceTestTemplate,
    NavbarTest,
    YEMSQPlusInterpretation,
    MentalReadinessForChangeInterpretation,
    NeoInterpretation,
    DisappointmentBeckInterpretation,
    BodyShamingInterpretation,
    QolInterpretation,
    GeneralInterpretation,
    SexualFunctionInterpretation,
    BeckInterpretation,
    Tpcinterpretation,
    SclInterpretation,
    StlsInterpretation
  },
  created() {
    this.getInterpretation()
  },
  data() {
    return {
      interpretations: [
        {
          id: '1',
          component: 'BeckInterpretation'
        },
        {
          id: '3',
          component: 'sclInterpretation'
        },
        {
          id: '4',
          component: 'Tpcinterpretation'
        },
        {
          id: '7',
          component: 'StlsInterpretation'
        },
        {
          id: '8',
          component: 'SexualFunctionInterpretation'
        }
      ],
      templates: [
        {
          name: 'General',
          component: 'GeneralInterpretation'
        },
        {
          name: "sclTemplate",
          component: 'sclInterpretation',
        },
        {
          name: "beckTemplate",
          component: 'BeckInterpretation'
        },
        {
          name: 'tcpTemplate',
          component: 'Tpcinterpretation',
        },
        {
          name: 'StlsTemplate',
          component: 'StlsInterpretation'
        },
        {
          name: 'QolInterpretation',
          component: 'QolInterpretation'
        },
        {
          name: "BodyShaming",
          component: "BodyShamingInterpretation",
        },
        {
          name: "DisappointmentTest",
          component: "DisappointmentBeckInterpretation",
        },
        {
          name: "NeoInterpretation",
          component: 'NeoInterpretation'
        },
        {
          name: "MRFC",
          component: 'MentalReadinessForChangeInterpretation'
        },
        {
          name: "YEMSQ",
          component: 'YEMSQPlusInterpretation'
        },
        {
          name: "advanceTestTemplate",
          component: 'AdvanceTestTemplate'
        },
      ],
      report: null,
      run_id: null,
      run_attemp: 10,
      ai_response: null,

    }
  },
  computed: {
    testTarget() {
      return this.interpretations.find(item => item.id == this.$route.params.testID)
    },
    targetTemplate() {
      if (this.report) {
        return this.templates.find(item => item.name == this.report.test.template.name)
      }
      return null
    },
    ...mapGetters({
      'isLoggedIn': 'getIsLoggedIn',
    })
  },
  methods: {
    registerOrTestPanel() {
      if (this.isLoggedin) {
        this.$router.push({name: "tests"})
      } else {
        this.$emitEvent('openModalRegister')
      }
    },
    getInterpretation() {
      this.requesting('test', 'getInterpretation', {
            'userTestID': this.$route.params.userTestId,
            'testID': this.$route.params.testID,
          }
      ).then((resp) => {
        this.report = resp.data[0]
        if (this.report.ai_response) {
          this.ai_response = JSON.parse(this.report.ai_response)
        }
        if (resp.data[0].hasOwnProperty('guest_participant_id')) {
          this.$store.dispatch('setGuestParticipantId', resp.data[0].guest_participant_id)
        }
      });
    },
    getAiData() {
      this.ai_response = 'loader'
      this.requesting('test', 'sendDataToAi', {}, {
        'message': 'در تست استاندارد افسردگی بک نمره ی ' + this.report.result[0].score.sum + ' گرفته ام ',
        "assistant_id": 47,
        'run': true,
      }).then((resp) => {
        this.run_id = resp.data.run.gpt_run_id
        setTimeout(() => {
          this.getRunData()
        }, 2000)
      })
    },
    goToTop() {
      window.scrollTo({
        top: 0
      });
    },
    getRunData() {
      this.run_attemp--
      if (this.run_attemp != 0) {
        this.requesting('test', 'getResponseAi', {}, {
          "run_gpt_id": this.run_id,
        }).then((resp) => {
          if (resp.data.run.status == 'completed') {
            this.ai_response = JSON.parse(resp.data.message)
            this.storeAiResoinseOnInterpretation()
          } else {
            setTimeout(() => {
              this.getRunData()
            }, 2000)
          }
        })
      }
    },
    storeAiResoinseOnInterpretation() {
      this.requesting('test', 'storeAiResponse', {
        'userTestId': this.$route.params.userTestId,
        'testId': this.$route.params.testID,
      }, {
        ai_response: JSON.stringify(this.ai_response)
      }).then((resp) => {
        console.log(resp, '12121')
      })
    }
  },
}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 600px) {
  .footerContent {
    width: 80% !important;
  }
}


.interpretationArea {
  /*position: relative;*/
  min-height: 100vh;
  overflow-x: hidden;
}


.circleOne {
  background: #dff8ff;
  z-index: 0 !important;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  top: -300px;
  left: -300px;
  position: absolute;
  filter: blur(20px);
}

.circlefour {
  background: #dff8ff;
  z-index: 0;
  width: 850px;
  height: 850px;
  border-radius: 50%;
  bottom: 0px;
  right: -200px;
  position: absolute;
  filter: blur(20px);
}

.circleTwo {
  position: absolute;
  background: #f0f1ff;
  top: 600px;
  left: -140px;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  filter: blur(20px);
}

.circleThree {
  position: absolute;
  background: #e0fff6;
  top: 200px;
  right: -150px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(20px);
}

.interpretationContent {
  /*position: absolute;*/
  margin-top: 100px;
  z-index: 10 !important;
  position: relative;
}

.footer {
  position: relative;
  z-index: 10;
}

.footerContent {
  width: 60%;
  margin: 0px auto;
}

.footerItem > .footerItemTitle {
  font-size: 19px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  /*line-height: 2.57;*/
  letter-spacing: normal;
  text-align: right;
  color: #b9b9b9;
  margin-bottom: 10px;
}

.footerItem > .footerItemContent {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
}

.linkExternal {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
}

.linkExternal:hover {
  color: #000000;
  transition: all 0.3s;
}

.footerSubTitle {
  color: #6d6d6d;
}

.termsBtn {
  width: 122px !important;
  height: 27px !important;
  background-color: #46b1a1 !important;
  border-radius: 10px 10px 0px 0px !important;
}

a {
  text-decoration: none;
}
</style>