<template>
  <div>
    <p class="titleArea">
      {{ $t('testimonialTitle') }}
    </p>
    <v-row align="center" justify="center">
      <v-col>
        <hooper
            :rtl="$vuetify.rtl"
            :itemsToShow="itemToShow"
            :autoPlay="true"
            :playSpeed="15000"
            style="min-height:400px;"
            v-if="showSlider"
            :infiniteScroll="true"
            :wheel-control="false"
        >
          <slide v-for="(item , i) in comments" :key="i" class="px-2 py-5">
            <div class="singleComments" :style="{backgroundColor:item.color}">
              <div class="d-flex">
                <div>
                  <img :src="require(`../../assets/countryflags/${item.flag}`)" alt="">
                </div>
                <div class="name mt-2 mr-2">
                  {{ $t(`${item.title}`) }}
                </div>
              </div>
              <div class="desc mt-3">
                {{ $t(`${item.desc}`) }}
              </div>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*eslint-disable*/
import {
  Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  props: {
    itemToShows: {
      default: null,
    }
  },
  computed: {
    itemToShow() {
      if (this.itemToShows == null) {
        return this.$vuetify.breakpoint.mdAndUp ? 2 : 1;
      }
      return this.itemToShows
    }
  },
  data() {
    return {
      showSlider: true,
      comments: [
        {
          title: 'onBoarding.testimonial.sweden.title',
          desc: 'onBoarding.testimonial.sweden.desc',
          color: "#d1fdff",
          flag: 'sweden.png'
        },
        {
          title: 'onBoarding.testimonial.usa.title',
          desc: 'onBoarding.testimonial.usa.desc',
          color: "#d1dcff",
          flag: 'usa.png'
        },
        {
          title: 'onBoarding.testimonial.germany.title',
          desc: 'onBoarding.testimonial.germany.desc',
          color: "#d1fdff",
          flag: 'germany.png'
        },
        {
          title: 'onBoarding.testimonial.usaTwo.title',
          desc: 'onBoarding.testimonial.usaTwo.desc',
          color: "#d1dcff",
          flag: 'usa.png'
        },
        {
          title: 'onBoarding.testimonial.canada.title',
          desc: 'onBoarding.testimonial.canada.desc',
          color: "#d1fdff",
          flag: 'canada.png'
        },
        {
          title: 'onBoarding.testimonial.netherlands.title',
          desc: 'onBoarding.testimonial.netherlands.desc',
          color: "#d1dcff",
          flag: 'netherlands.png'
        }
      ]
    }
  },
  components: {
    Hooper,
    Slide,
    // CountryFlag,
    HooperNavigation,
    HooperPagination
  },
  watch: {
    '$vuetify.breakpoint.mdAndUp'() {
      this.showSlider = false;
      this.$nextTick().then(() => {
        this.showSlider = true;
      })
    },

  }
};
</script>
<style>
@media (min-width: 600px) and (max-width: 1500px) {
  .singleComments {
    height: 300px !important;
  }
}

.titleArea {
  color: #574596;
  font-size: 14px;
  text-align: center;
}

.singleComments {
  padding: 24px 50px 24px 36px;
  /*opacity: 0.5;*/
  height: 250px;
  border-radius: 10px;
  border: solid 1px #4576d8;
  /*background-color: #d1fdff;*/
}

.singleComments img {
  width: 36px;
  height: 36px;
  border-radius: 50%
}

.singleComments .name {
  font-size: 14px;
}

.singleComments .desc {
  font-size: 14px;
  font-weight: 100;
  line-height: 2;
}
</style>