<template>
  <div>
    <div class="featureContent">
      <div class="titleArea">
        سیمیاروم بهترین مشاوران را برای شما فراهم می‌کند
      </div>
      <v-row class="mt-10 text-center">
        <v-col cols="12" lg="3" md="6">
          <div class="singleFeature">
            <img src="../../../assets/tests/setSessuon.svg" alt="">
            <p class="mt-2">
              برگزار جلسات در زمان
              <br>
              مناسب شما
            </p>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="6">
          <div class="singleFeature">
            <img src="../../../assets/tests/refund.svg" alt="">
            <p class="mt-2">
              بازگشت وجه در صورت عدم
              <br>
              تمایل به ادامه مشاوره
            </p>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="6">
          <div class="singleFeature">
            <img src="../../../assets/tests/change.svg" alt="">
            <p class="mt-2">
              امکان تعییر تراپیست در طول
              <br>
              دوره مشاوره
            </p>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="6">
          <div class="singleFeature">
            <img src="../../../assets/tests/support.svg" alt="">
            <p class="mt-2">
              پشتیبان اختصاصی در طول
              <br>
              دوره مشاوره
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 600px) {
  .featureContent{
    width: 60% !important;
    margin: 40px auto 0px !important;

  }
  .titleArea{
    font-size: 15px !important;
  }
}
.featureContent{
  width: 40%;
  margin: 50px auto 0px;
}
.titleArea{
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.singleFeature img{
  width: 50px;
}
.singleFeature{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
</style>