<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center">
        <p>امتیاز آزمون</p>
        <p>تفسیر {{ report.test.title_fa }} </p>
        <div class="gauge ">
          <vue-apex-charts type="bar" :height="$vuetify.breakpoint.mdAndDown? 300:'auto'" :options="chartOptions"
                           :series="series"
                           v-if="zonesScore.length > 0 && zonesName.length > 0 && maxScore != 0"></vue-apex-charts>
          <v-btn color="#46b1a1" class="white--text " elevation="0" @click="registerOrTestPanel">
            مقایسه نتیجه تست
            <v-icon class="mr-3">mdi-chart-bar</v-icon>
          </v-btn>
        </div>
        <div class="hintArea ">
          <div class="">

            <div class="pr-4">
            <span>
              <v-icon>mdi-alert</v-icon>
            </span> {{ report.test.description_en }}
            </div>
          </div>
        </div>
      </div>
      <div class="textInterpretation mt-10">
        <v-row>
          <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
            <div class="textInterpretationTitle">
            </div>
            <!--            <div class="shortInterpretation">-->
            <!--              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.-->
            <!--            </div>-->
            <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">
              <div class="shortInterpretation">
                <div class="titleShortInterpretation">
                  <!--                  ناراحتی و غم-->
                </div>
                <div class="interpretationText" :id="item.id" v-html="item.report.description_for_client_fa"></div>
                <div class="hintShortInterpretation">
                  <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <ConvertToPdf file-name="sclInterpretation">
      <template v-slot:pdf_content>
        <div class="contentAreaPdf">
          <div style="margin: 0px 100px 0px 100px ">
            <vue-apex-charts type="bar" :height="$vuetify.breakpoint.mdAndDown? 300:'auto'" :options="chartOptions"
                             :series="series" v-if="zonesScore.length > 0 && zonesName.length > 0"></vue-apex-charts>
          </div>
          <br>
          <div style="margin: 0px 100px">
            <div class="singleInterpretation" style="text-align: right" dir="rtl" v-for="(item , n) in report.reports"
                 :key="n">
              <div class="shortInterpretation">
                <div class="titleShortInterpretation">
                  <!--                  ناراحتی و غم-->
                </div>
                <div :id.camel="item.id" v-html="item.report.description_for_client_fa"></div>
                <div class="hintShortInterpretation">
                  <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ConvertToPdf>
    <br>

<!--    <PsyTestPreSessionRequest class="mt-10 PriceBoxBeck" v-if="showPresession"/>-->

    <PriceBoxBeck/>
  </div>
</template>

<script>
/*eslint-disable*/
// import ImprovementHint from "@/components/tests/interpretation/beck/improvementHint";
// import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
import VueApexCharts from 'vue-apexcharts'
import ConvertToPdf from "@/components/Test/Main/ConvertPdf";
import {mapGetters} from "vuex";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import PsyTestPreSessionRequest from "../../../../components/Test/Main/PsyTestPreSessionRequest";
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";

export default {
  props: {
    report: Object,
  },
  components: {
    PriceBoxBeck,
    PsyTestPreSessionRequest,
    ConvertToPdf,
    // PriceBoxBeck,
    // ImprovementHint,
    VueApexCharts
  },
  data() {
    return {
      zonesName: [],
      zonesScore: [],
      maxScore: 0,
      showPresession:false,
    }
  },
  mounted() {
    this.prepareChartInfo();
    if (this.profile) {
      this.showPresession = true
      // this.checkShowPreSessionBanner();
    }
    // console.log(this.report.result[0].score.average)
  },
  methods: {
    registerOrTestPanel() {
      this.$emit('registerOrTestPanel')
    },
    prepareChartInfo() {
      this.report.result.forEach(item => {
        if (item.name != 'Main Zone' && item.name && !item.hasOwnProperty('variable')) {
          this.zonesName.push(item.name)
          if (item.score.hasOwnProperty('average')) {
            this.zonesScore.push(item.score.average)
          } else {
            this.zonesScore.push(item.score.sum)
          }
          if (item.max_score > this.maxScore && item.max_score) {
            // console.log(item.max_score)
            this.maxScore = item.max_score
          }
        }
      })
      // console.log(this.zonesName, this.zonesScore)
      // alert(this.maxScores)
    },
    checkShowPreSessionBanner() {
      const parsedNumber = parsePhoneNumberFromString(this.profile.cel);
      if (parsedNumber.country != 'IR') {
        this.showPresession = true
      }
    }
  },
  computed: {
    series() {
      return [{
        name: '',
        data: this.zonesScore
      }]
    },
    chartOptions() {
      return {
        chart: {
          height: 200,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '45%',
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 1
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -35,
            // rotateAlways: true,
            hideOverlappingLabels: false,
            // showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'inherit',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: -20,
            offsetY: 70,

          },
          categories: this.zonesName
        },
        yaxis: {
          min: 0,
          max: this.maxScore,
          forceNiceScale: true,
          decimalsInFloat: false,
          title: {
            text: '',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        },
      }
    },
    ...mapGetters({
      profile: 'getUserInfo',
    }),
  },
  watch: {
    report() {
      this.prepareChartInfo()
    },
    profile(val) {
      if (val) {
        this.checkShowPreSessionBanner()
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }

  .gauge {
    width: 100% !important;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.contentAreaPdf {
  width: 90%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
}

.gauge {
  width: 80%;
  margin: 50px auto -20px auto;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}


.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>