<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center">
        <p>امتیاز آزمون</p>
        <p>{{ report.test.title_fa }}</p>
        <div class="gauge ">
          <vue-svg-gauge
              :start-angle="-90"
              :end-angle="90"
              :value="score"
              :separator-step="0"
              :min="report.result[0].min_score"
              :max="report.result[0].max_score"
              :scale-interval="0"
          />
          <div class="gaugeInfo" :style="{'color':gaugeColor}">
            {{ score }}
          </div>
          <div class="px-3 text-left mt-4 d-flex justify-space-between">
            <div>{{ report.result[0].max_score }}</div>
            <div class="pl-1">{{ report.result[0].min_score }}</div>
          </div>
        </div>
        <v-btn color="#46b1a1" class="white--text mt-10" x-large elevation="0" @click="registerOrTestPanel">
          مقایسه نتیجه تست
          <v-icon class="mr-3">mdi-chart-bar</v-icon>
        </v-btn>
        <!--        <div class="hintArea ">-->
        <!--          <div class="d-flex justify-space-between  ">-->
        <!--            <div class="">-->
        <!--              &lt;!&ndash;              <v-icon>mdi-alert</v-icon>&ndash;&gt;-->
        <!--            </div>-->
        <!--            <div class=" pr-4">-->

        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="textInterpretation">
          <v-row>
            <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
              <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">
                <div class="shortInterpretation">
                  <div class="titleShortInterpretation">
                    <!--                  ناراحتی و غم-->
                  </div>
                  <div v-html="item.report.description_for_client_fa"></div>
                  <!--                <div class="hintShortInterpretation">-->
                  <!--                  &lt;!&ndash;                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.&ndash;&gt;-->
                  <!--                </div>-->
                </div>
              </div>
              <div class="mt-5">
                <v-row v-if="report.reports[0].report.id == '139'">
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.imdb.com/title/tt2096673/" target="_blank">
                      <img src="../../../../assets/tests/MRFC/7aiodDn7fqWuTcAwBrIuuVqOG2RYvRunWnLyNnu1.jpg" width="90%"
                           alt="">
                      <p class="text-center mt-2">
                        Inside Out
                      </p>
                    </a>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.amazon.de/-/en/Carol-S-Dweck/dp/0345472322" target="_blank">
                      <img src="../../../../assets/tests/MRFC/bhDfjzeFhyKY3QhLaSgdI1eSmJM7lAYFTpVZ1n7Z.jpg" width="90%"
                           alt="">
                      <p class="mt-7 text-center">
                        Mindset: The New Psychology of Success by Carol S
                      </p>
                    </a>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.pushkin.fm/podcasts/the-happiness-lab-with-dr-laurie-santos" target="_blank">
                      <img src="../../../../assets/tests/MRFC/zSMTDoELzjDs9XqHfGX6ODghaflO6N6TT0zBv7a8.png" width="90%"
                           alt="">
                      <p class="text-center">
                        The Happiness Lab by Dr. Laurie Santos
                      </p>
                    </a>
                  </v-col>
                </v-row>
                <v-row v-else-if="report.reports[0].report.id == '138'">
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.amazon.de/-/en/Kelly-McGonigal/dp/1583335080" target="_blank">
                      <img src="../../../../assets/tests/MRFC/138/1.png" class="bookImage" width="90%"
                           alt="">
                      <p class="text-center mt-2">
                        The Willpower Instinct
                      </p>
                    </a>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.imdb.com/title/tt0107048/" target="_blank">
                      <img src="../../../../assets/tests/MRFC/138/2.png" class="bookImage" width="90%"
                           alt="">
                      <p class="mt-2 text-center">
                        Groundhog Day
                      </p>
                    </a>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://hiddenbrain.org/" target="_blank">
                      <img src="../../../../assets/tests/MRFC/138/3.png" class="bookImage" width="90%"
                           alt="">
                      <p class="text-center">
                        The Hidden Brain by Shankar Vedantam
                      </p>
                    </a>
                  </v-col>
                </v-row>
                <v-row v-else-if="report.reports[0].report.id == '140'">
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.imdb.com/title/tt1626146/" target="_blank">
                      <img src="../../../../assets/tests/MRFC/140/inzlUB8OjijhvHujYMBlk3gUtBKzTWNMIMt0cuwJ.jpg"
                           class="bookImage" width="90%"
                           alt="">
                      <p class="text-center mt-2">
                        Hector and the Search for Happiness
                      </p>
                    </a>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.amazon.de/-/en/Mihaly-Csikszentmihalyi/dp/0061339202" target="_blank">
                      <img src="../../../../assets/tests/MRFC/140/wUJp22P7SP1XD7En2Iob0tEdcc5qtrlfrF7OqQeY.jpg"
                           class="bookImage" width="90%"
                           alt="">
                      <p class="mt-2 text-center">
                        Flow: The Psychology of Optimal Experienc
                      </p>
                    </a>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" class="text-center">
                    <a href="https://www.hubermanlab.com" target="_blank">
                      <img src="../../../../assets/tests/MRFC/140/YjzEgoL9jd8z3VSHS6og5WMnJ6T9GV9h6lhy7OD8.jpg"
                           class="bookImage" width="90%"
                           alt="">
                      <p class="text-center">
                        The Huberman Lab Podcast
                      </p>
                    </a>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <br>
          <br>
          <br>
        </div>

      </div>
    </div>
    <br>
    <br>
    <PriceBoxBeck/>
  </div>
</template>

<script>
/*eslint-disable*/
import {VueSvgGauge} from 'vue-svg-gauge'
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";


export default {
  props: {
    report: Object,
  },
  components: {
    PriceBoxBeck,
    VueSvgGauge,
  },
  data() {
    return {}
  },
  computed: {
    offset() {
      return this.report.result[0].min_score;
    },
    score() {
      return this.report.result[0].score.sum;
    },
    titleInterpretation() {
      if (this.score >= 0 && this.score <= 5) {
        return 'بدون شرمساری'
      } else if (this.score >= 6 && this.score <= 10) {
        return 'شرمساری خفیف'
      } else if (this.score >= 11 && this.score <= 20) {
        return 'شرمساری متوسط'
      } else {
        return 'شرمساری زیاد'
      }
    },

  },
  methods: {
    registerOrTestPanel() {
      this.$emit('registerOrTestPanel')
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }
}

.bookImage {
  height: 400px;
}

a {
  color: inherit;
}

.imageEmoji {
  width: 100%;
  filter: grayscale(1);
  opacity: 0.5;
}

.EmojiTex {
  text-align: center;
  top: 10px;
}

.activeImage {
  filter: none !important;
  opacity: 1 !important;


}

.shortInterpretation div p img {
  display: none !important;
  width: 100% !important;
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
}

.gauge {
  width: 200px;
  margin: 50px auto -20px auto;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>