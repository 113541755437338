<template>


  <div class="d">
    <div class="white">
      <div class="collageBanner"
           style="display: flex;flex-direction: column;justify-content: center;align-content: center">
        <div class="contentCollegeBanner">
          <div class="titleCollege">
            اگر نیاز به یک همراه دارید ما در کنارتان هستیم
          </div>
          <p style="line-height: 30pt;text-align: justify">
            برای آگاهی بیشتر از وضعیت سلامت روان خود و دانستن جزئیات مسائلی که در این تست مطرح شده است، می‌توانید یک جلسه کوتاه ارزیابی با کارشناسان روانشناسی سیمیاروم به صورت رایگان داشته باشید.
          </p>
          <a class="preSessionTestLink">
            <div class="actionCollage preSessionTestDiv" @click="redirectToPreSession">
              درخواست جلسه پیش‌مشاوره
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="mb-15">
      <RegisteringSection/>
    </div>
  </div>


</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import RegisteringSection from "@/components/Test/Main/RegisteringSection";

export default {
  components: {RegisteringSection},

  data() {
    return {
      customerType: 'new',
      switchLock: false,
    }
  },
  methods: {
    redirectToPreSession() {
      //
      // console.log(this.$route.params.testID)
      if (this.$route.params.testID == '3') {
        this.$emitEvent('setLoading',true)
        this.requesting('user', 'request-test-analysis', {}, {
          test_link: this.$route.path
        }).then((resp) => {
          this.$emitEvent('setLoading',false)
          this.$emitEvent('notify', {title: resp.message, color: 'green'})
        }).catch(() => {
          this.$emitEvent('setLoading',false)
        })
      } else {
        window.href = 'https://simiaroom.com/pre-session/'
      }
    },
    gotoPackage(id) {
      this.$router.push(`/${this.$route.params.lang}/onboarding?package_id=${id}`)
    },
    switcherHandlerCustomer() {
      if (!this.switchLock) {
        this.switchLock = true
        if (this.customerType === 'new') {
          gsap.fromTo('.switch-pointer', {right: '5px'}, {left: '5px', right: 'auto', duration: .15}).then(() => {
            this.customerType = 'old'
            this.switchLock = false
          })
        } else {
          gsap.to('.oldCustomer', {display: 'none'})
          gsap.fromTo('.switch-pointer', {left: '5px'}, {right: '5px', left: 'auto', duration: .15}).then(() => {
            this.switchLock = false
            this.customerType = 'new'
          })
        }
      }
    }
  }
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 1500px) {
  .arrowArea {
    transform: rotate(270deg);
    width: 100px !important;
    margin: 50px auto;
  }

  .processItem {
    min-width: 200px !important;
  }

  .titleContentBox {
    font-size: 17px !important;
  }

  .collageBanner {
    background: none !important;
    border-top: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
  }

  .contentCollegeBanner {
    width: 90% !important;
    margin: 0px auto !important;
    text-align: center;
  }

  .actionCollage {
    margin: 0px auto !important;
  }

  .titleCollege {
    font-size: 20px !important;
  }

  .contentCollegeBanner p {
    font-size: 18px !important;
  }
}


.titleContentBox {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6d5aac;
}

.processArea {
  /*width: 60% ;*/
  margin: 0px auto;
}

.arrowArea {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.processArea {
  width: 50%;
  /*background-color: red;*/
  /*background-color: blue;*/
  margin: 100px auto;
}

.processItem {
  text-align: center;
  /*background-color: red;*/
  flex-grow: 1;
  /*min-width: 200px;*/
  /*max-width: 200px;*/
}

.processItem img {
  width: 100px;
  margin: 0px auto;
  text-align: center;

}

.processItemTitle {
  font-size: 15px;
  margin-top: 20px;
  line-height: 30pt;
}


.collageBanner {


  /*box-shadow: 0px 50px 50px -50px rgba(0, 0, 0, 0.1 );*/
  /*border: 1px solid rgba(0, 0, 0, 0.3);*/
  background: url("../../../assets/image/daneshjoie.jpg") no-repeat;
  background-size: contain;
  height: 350px;
  /*margin-top: 100px;*/
  margin: 100px 20px 100px 20px;
}

.contentCollegeBanner {
  width: 50%;
  margin: 0px 10%;
}

.titleCollege {
  font-size: 20px;
  color: #39B787;
  margin-bottom: 10px;
}

.contentCollegeBanner p {
  font-size: 15px;
  margin-bottom: 20px;
}

.actionCollage {
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #39B787;
  width: 200px;
  font-size: 13px;
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  /*margin-right: 18%;*/
}


@media (min-width: 200px ) and (max-width: 1200px) {
  .priceBox {
    height: auto !important;
    padding: 20px;
  }

  .contentArea {
    width: 100% !important;
  }

  .singlePriceBox {
    width: 90% !important;
  }

  .badge {
    left: -20px !important;
  }
}

.priceBox {
  min-height: 650px;
  background: url("../../../assets/tests/backPriceBox.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 25px;
}

.badge-custom {
  background: url("../../../assets/tests/bageTese.svg");
  width: 62px;
  height: 62px;
  position: absolute;
  top: -15px;
  left: -20px;
  line-height: 20px;
  color: white;
  text-align: center;
  font-size: 12px;
}

.glassDivOne {
  width: 26px;
  height: 27px;
  opacity: 0.2;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  top: -5px;
  right: -10px;
}

.glassDivTwo {
  width: 56px;
  height: 55px;
  opacity: 0.1;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  bottom: -10px;
  left: 5px;
  z-index: 999;
}

.glassDivThree {
  width: 18px;
  height: 17px;
  opacity: 0.3;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 70px;
  left: -20px;
}

.contentArea {
  width: 50%;
  margin: 0px auto;
}

.womanPic {
  position: absolute;
  bottom: 0px;
  width: 450px;
  right: 20px;
}

.titleArea {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}


.singlePriceBox {
  width: 100%;
  min-height: 350px;
  margin: 0px 10px 0px auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: #f6f8fc;
  cursor: pointer;
  position: relative;

}

.titleAreaPriceBoxView {
  min-height: 144px;
  background: #edf0f9;
  border-radius: 8px 8px 0px 0px;
}

.titlePriceBox {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: #574596;
  margin-bottom: 5px;
}

.enTitlePriceBox {
  font-size: 17px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #574596;
}

.infoPriceBox {
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  /*line-height: 1.57;*/
  letter-spacing: normal;
  color: #574596;
  margin: 0px 10px 0px 10px;
}

.oldPrice {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /*line-height: 1.83;*/
  letter-spacing: normal;
  text-align: center;
  color: #574596;
  margin: 0px 10px 0px 10px;
}

.packageInfo {
  border-top: 1px solid white;
  min-height: 206px !important;
}

.countPackage {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.packageOptions {
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionArea {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

.countPackageOldCustomer {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.switcher {
}

.switchArea {
  margin: 0px auto;
  width: 110px;
  height: 54px;
  border-radius: 27px;
  padding: 0px 2px 0px 2px;
  border: solid 1px #4576d8;
  background-color: #d1fdff;
  text-align: right;
  position: relative;
}

.switch-pointer {
  position: absolute;
  width: 45px;
  background: #46b1a1;
  top: 3px;
  bottom: 3px;
  border-radius: 50%;
}

.switch-pointer-newCustomer {
  right: 5px;
  transition: all 0.3s;
}

.switch-pointer-oldCustomer {
  transition: all 0.3s;
  left: 5px;
}

</style>