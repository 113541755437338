<template>
  <div>

  </div>
</template>

<script>
import {VueSvgGauge} from 'vue-svg-gauge'
import ImprovementHint from "@/components/Test/Beck/improvmentHint";
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
import FeaturesSimiaBeck from "@/components/Test/Beck/FeaturesSimiaBeck";
import SuggestTherapists from "@/components/Test/Beck/SuggestTherapists";
import TestimonialsOnboarding from "@/components/MatchMaking/TestimonialsOnboarding";
import EmojiAreaBeck from "@/components/Test/Beck/EmojiAreaBeck";


/*eslint-disable*/
export default {
  props: {
    report: Object,
  },
  components: {
    EmojiAreaBeck,
    TestimonialsOnboarding,
    SuggestTherapists,
    FeaturesSimiaBeck,
    PriceBoxBeck,
    ImprovementHint,
    VueSvgGauge,
  },
  data() {
    return {}
  },
  computed: {
    score() {
      return this.report.result[0].score.sum;
    },
    gaugeColor() {
      if (this.score >= 0 && this.score <= 13) {
        return '#76d987'
      } else if (this.score >= 14 && this.score <= 19) {
        return '#6ec9b5'
      } else if (this.score >= 20 && this.score <= 28) {
        return '#76bfd9'
      } else {
        return '#7695d9'
      }
    },
    titleInterpretation() {
      if (this.score >= 0 && this.score <= 13) {
        return 'عدم افسردگی'
      } else if (this.score >= 14 && this.score <= 19) {
        return 'افسردگی خفیف'
      } else if (this.score >= 20 && this.score <= 28) {
        return 'افسردگی متوسط'
      } else {
        return 'افسردگی شدید'
      }
    },
    emojiInterpretations() {
      if (this.score >= 0 && this.score <= 13) {
        return require('../../../assets/tests/noafsorde.png')
      } else if (this.score >= 14 && this.score <= 19) {
        return require('../../../assets/tests/litterAfsorder.svg')
      } else if (this.score >= 20 && this.score <= 28) {
        return require('../../../assets/tests/mediumAfsorder.svg')
      } else {
        return require('../../../assets/tests/afsorde.svg')
      }
    }
  }
}
</script>

