<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center">
        <p>امتیاز آزمون</p>
        <p>{{ report.test.title_fa }}</p>
        <div class="chartArea  mt-10">
          <v-row>
            <v-col cols="12" lg="6" class="">
              <table style="width: 100%" cellspacing="18">
                <tr class="tableHeader">
                  <th class="text-right">ویژگی‌ اصلی</th>
                  <th>نمره‌ خام</th>
                  <th>درصد</th>
                  <th>وضعیت</th>
                </tr>
                <tr class="tableContent" v-for="(zone , i ) in zones" :key="i">
                  <td class="text-right d-flex">
                    <div :style="{background:zone.color}"
                         class="ml-1 white--text"
                         style="width: 15px;height: 15px;border-radius: 20px;text-align: center">
                      {{ zone.letter }}
                    </div>
                    <div>
                      {{ zone.name }}
                    </div>
                  </td>
                  <td>
                    {{ zone.score }}
                  </td>
                  <td>
                    {{ preparePercent(zone.score) }}%
                  </td>
                  <td>
                    {{ prepareStatus(zone.score) }}
                  </td>
                </tr>
              </table>
              <div class="tableArea">
              </div>
            </v-col>
            <v-col cols="12" lg="6" class="" v-if="$vuetify.breakpoint.mdAndUp">
              <div style="height: 50px;"></div>
              <div class="" v-for="(zone , i ) in zones" :key="i">
                <div class="ChartBorderGrey">
                  <div class="ChartBorder white--text"
                       :style="{width:`${preparePercent(zone.score)}%`,background:zone.color}">
                    {{ preparePercent(zone.score) }}%
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

        </div>
        <v-btn color="#46b1a1" class="white--text mt-8" elevation="0"  @click="registerOrTestPanel">
          مقایسه نتیجه تست
          <v-icon class="mr-3">mdi-chart-bar</v-icon>
        </v-btn>
      </div>
      <div class="textInterpretation mt-10">
        <v-row>
          <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
            <!--            <div class="shortInterpretation">-->
            <!--              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.-->
            <!--            </div>-->
            <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">
              <div class="shortInterpretation">
                <div class="titleShortInterpretation">
                  <!--                  ناراحتی و غم-->
                </div>
                <div v-html="item.report.description_for_client_fa"></div>
                <div class="hintShortInterpretation">
                  <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <br>
        <br>
        <br>
      </div>
    </div>
    <br>
<!--    <ConvertToPdf file-name="tcpInterpretation">-->
<!--      <template v-slot:pdf_content>-->
<!--        <div class="charting white text-center" dir="rtl">-->
<!--          <p style="text-align: center">امتیاز آزمون</p>-->
<!--          <p style="text-align: center">{{ report.test.title_fa }}</p>-->
<!--          <div class="chartArea  mt-10">-->
<!--            <v-row>-->
<!--              <v-col cols="12" lg="12" class="">-->
<!--                <table style="width: 100%" cellspacing="18">-->
<!--                  <tr class="tableHeader">-->
<!--                    <th class="text-right">ویژگی‌ اصلی</th>-->
<!--                    <th>نمره‌ خام</th>-->
<!--                    <th>درصد</th>-->
<!--                    <th>وضعیت</th>-->
<!--                  </tr>-->
<!--                  <tr class="tableContent" v-for="(zone , i ) in zones" :key="i">-->
<!--                    <td class="text-right d-flex">-->
<!--                      <div :style="{background:zone.color}"-->
<!--                           class="ml-1 white&#45;&#45;text"-->
<!--                           style="width: 15px;height: 15px;border-radius: 20px;text-align: center">-->
<!--                        {{ zone.letter }}-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        {{ zone.name }}-->
<!--                      </div>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      {{ zone.score }}-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      {{ preparePercent(zone.score) }}%-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      بسیار‌ بالا-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                </table>-->
<!--                <div class="tableArea">-->
<!--                </div>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </div>-->
<!--          &lt;!&ndash;          <div class="hintArea " dir="rtl">&ndash;&gt;-->
<!--          &lt;!&ndash;            <div class="d-flex justify-space-between  ">&ndash;&gt;-->
<!--          &lt;!&ndash;              <div class="">&ndash;&gt;-->
<!--          &lt;!&ndash;                <v-icon>mdi-alert</v-icon>&ndash;&gt;-->
<!--          &lt;!&ndash;              </div>&ndash;&gt;-->
<!--          &lt;!&ndash;              <div class=" pr-4">&ndash;&gt;-->
<!--          &lt;!&ndash;                این تست جهت بررسی وضعیت عملکرد و احتمال وجود اهمال کاری بوده و سنجه مناسبی تشخیص نهایی نیست&ndash;&gt;-->
<!--          &lt;!&ndash;              </div>&ndash;&gt;-->
<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="textInterpretation mt-10">-->
<!--          <v-row>-->
<!--            <v-col cols="12" lg="9 " class="" style="margin: 0px auto" dir="rtl">-->
<!--              &lt;!&ndash;            <div class="shortInterpretation">&ndash;&gt;-->
<!--              &lt;!&ndash;              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.&ndash;&gt;-->
<!--              &lt;!&ndash;            </div>&ndash;&gt;-->
<!--              <div class="singleInterpretation " v-for="(item , n) in report.reports" :key="n" dir="rtl">-->
<!--                <div class="shortInterpretation">-->
<!--                  <div class="titleShortInterpretation">-->
<!--                    &lt;!&ndash;                  ناراحتی و غم&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div style="margin: 100px" v-html="item.report.description_for_client_fa"></div>-->
<!--                  <div class="hintShortInterpretation">-->
<!--                    &lt;!&ndash;                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <br>-->
<!--          <br>-->
<!--          <br>-->
<!--        </div>-->
<!--      </template>-->
<!--    </ConvertToPdf>-->
    <br>
    <PriceBoxBeck />
  </div>
</template>

<script>
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
// import ConvertToPdf from "@/components/tests/convertToPdf";

export default {
  props: {
    report: Object,
  },

  components: {
    PriceBoxBeck,
  },
  data() {
    return {
      zones: []
    }
  },
  methods: {
    registerOrTestPanel(){
      this.$emit('registerOrTestPanel')
    },
    prepareZones() {
      console.log(this.report.result)
      this.report.result.forEach((item) => {
        if (item.name != "Main Zone") {
          let temp = {
            name: item.name,
            score: item.score.sum
          }
          switch (item.zone_id) {
            case 45:
              temp.color = "#FFB727"
              temp.letter = 'N'
              break
            case 46:
              temp.color = "#DB3C48"
              temp.letter = 'E'
              break
            case 47:
              temp.color = "#93359B"
              temp.letter = 'O'
              break
            case 48:
              temp.color = "#2F5FA5"
              temp.letter = 'A'
              break
            case 49:
              temp.color = "#11B21B"
              temp.letter = 'C'
              break
          }
          this.zones.push(temp)
        }
      })
      console.log(this.zones)
    },
    prepareStatus(score) {
      if (score > 0 && score <= 12) {
        return "بسیار پایین"
      } else if (score >= 13 && score <= 24) {
        return "پایین"
      }else if (score >= 25 && score <= 37) {
        return "بالا"
      }else {
        return "بسیار‌ بالا"
      }
    },
    preparePercent(score) {
      return parseInt(score * 100 / 48)
    }
  },
  computed: {
    offset() {
      return this.report.result[0].min_score;
    },
    score() {
      return this.report.result[0].score.sum;
    },
  },
  mounted() {
    this.prepareZones()
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }

  .chartArea {
    width: 100% !important;
  }

  .tableHeader {
    font-size: 11px !important;
  }
}

.ChartBorderGrey {
  border: 1px solid #ededed;
  height: 20px;
  margin-bottom: 18px;
  border-radius: 30px 0px 0px 30px;
}

.ChartBorder {
  background: blue;
  width: 100px;
  height: 20px;
  border-radius: 30px 0px 0px 30px;
  transition: all 0.3s;
}

.tableHeader {
  font-size: 15px;
}

.tableContent {
  font-size: 13px;
}

.chartArea {
  width: 80%;
  height: 250px;
  margin: 0px auto;
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
}

.gauge {
  width: 200px;
  margin: 50px auto -20px auto;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>