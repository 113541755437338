<template>
  <div>
    <v-app-bar elevation="1" :height="$vuetify.breakpoint.mdAndUp ? '80px' : '60px'" class="menuMob" color="white"
               fixed>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-row>
          <v-col cols="2" class="text-right flex-grow-1  mt-2">
            <router-link style="text-decoration: none;color: inherit" :to="{name:'dashboard',params:{lang:'fa'}}">
              <img src="../../assets/simialogopng.png" style="width: 150px;"
                   v-if="getPlatformInfo('id') == 3">
              <p v-else-if="getPlatformInfo('id') == 4">{{ getPlatformTitle() }}</p>
            </router-link>
          </v-col>
          <v-col cols="8" class="d-flex justify-center pt-5">
            <div class="d-flex">
              <div v-for="(item , i ) in menuItem" :key="i" class="mx-2">
                <a :href="item.href" style="text-decoration: none;color: inherit">
                  {{ item.title }}
                </a>
              </div>
            </div>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn elevation="0" class="loginBtn ml-2" :to="`/fa/login`"
                   id="login">
              ورود
            </v-btn>
            <v-btn elevation="0" class="registerBtn" :to="`/${$route.params.lang}/preorder/sign/68`"
                   id="reserveTestInterpretation">
              رزرو وقت مشاوره
            </v-btn>

          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="d-flex justify-space-between" style="width: 100%">
          <div>
            <v-app-bar-nav-icon @click="navigation = !navigation"></v-app-bar-nav-icon>
          </div>
          <v-spacer></v-spacer>
          <div class="pt-2">
            <router-link style="text-decoration: none;color: inherit" :to="{name:'dashboard',params:{lang:'fa'}}">
              <img src="../../assets/simialogopng.png" style="width: 150px;"
                   v-if="getPlatformInfo('id') == 3">
              <p v-else-if="getPlatformInfo('id') == 4">{{ getPlatformTitle() }}</p>
            </router-link>
          </div>
          <v-navigation-drawer app right v-model="navigation">
            <v-icon style="position:absolute;top: 10px;left: 10px" @click="navigation = !navigation">mdi-close</v-icon>
            <div class="pt-12">
              <div class="text-center">
                <img src="../../assets/simialogopng.png" style="width: 150px;"
                     v-if="getPlatformInfo('id') == 3">
                <p v-else-if="getPlatformInfo('id') == 4">{{ getPlatformTitle() }}</p>
              </div>
              <div class="mt-2 px-2">
                <div v-for="(item , i) in menuItem" :key="i" class="pb-2">
                  <a :href="item.href" style="text-decoration: none;color: inherit">
                    {{ item.title }}
                  </a>
                </div>
                <div class="mt-5">
                  <v-btn block elevation="0" class="loginBtn ml-2" :to="`/fa/login`"
                         id="login">
                    ورود
                  </v-btn>
                </div>
                <div class="mt-2">
                  <v-btn block elevation="0" class="registerBtn" :to="`/${$route.params.lang}/preorder/sign/68`"
                         id="reserveTestInterpretation">
                    رزرو وقت مشاوره
                  </v-btn>
                </div>
              </div>
            </div>
          </v-navigation-drawer>
        </div>
      </template>
    </v-app-bar>

  </div>
</template>

<script>
export default {
  data() {
    return {
      navigation: false,
      menuItem: [
        {
          href: 'https://simiaroom.com',
          title: 'مشاوره روانشناسی',
        },
        {
          href: 'https://simiaroom.com/counselor/',
          title: 'مشاوران',
        },
        {
          href: 'https://simiaroom.com/counseling/',
          title: "حوزه‌های مشاوره",
        },
        {
          href: 'https://simiaroom.com/blog/',
          title: "بلاگ"
        },
        {
          href: 'https://simiaroom.com/tests/',
          title: "تست‌ها‌ی روانشناسی",
        },
        {
          href: 'https://simiaroom.com/%D8%AF%D8%B1%D8%A8%D8%A7%D8%B1%D9%87-%D9%85%D8%A7/',
          title: "درباره‌ما"
        },
        {
          href: 'https://simiaroom.com/contact-us/',
          title: "تماس‌با‌ما"
        }
      ],

    }
  }
}
</script>

<style scoped>
.registerBtn {
  width: 132px !important;
  height: 34px !important;
  padding: 7px !important;
  border-radius: 10px;
  background-color: #46b1a1 !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.loginBtn {
  height: 34px !important;
  padding: 5px 27px;
  border-radius: 10px;
  border: 1px solid #46b1a1 !important;
  background: transparent !important;
  color: #46b1a1;
}

.menuMob {
  box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.16) !important;
  z-index: 9999;
}
</style>