import { render, staticRenderFns } from "./stlsInterpretation.vue?vue&type=template&id=13164568&scoped=true&"
import script from "./stlsInterpretation.vue?vue&type=script&lang=js&"
export * from "./stlsInterpretation.vue?vue&type=script&lang=js&"
import style0 from "./stlsInterpretation.vue?vue&type=style&index=0&id=13164568&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13164568",
  null
  
)

export default component.exports