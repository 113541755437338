<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center">
        <p>امتیاز آزمون</p>
        <p>تشخیص میزان اختلال عملکرد جنسی </p>
        <div class="gauge ">
          <vue-svg-gauge
              :start-angle="-90"
              :end-angle="90"
              :value="score"
              :separator-step="0"
              :min="offset"
              :max="(95 + offset)"
              :gauge-color="gaugeColor"
              :scale-interval="0"
          />
          <div class="gaugeInfo" :style="{'color':gaugeColor}">
            {{ score }}
          </div>
          <div class="px-3 text-left mt-4 d-flex justify-space-between">
            <div>{{ report.result[0].max_score }}</div>
            <div class="pl-1">{{ report.result[0].min_score }}</div>
          </div>
        </div>
        <div class="hintArea ">
          <div class="d-flex justify-space-between  ">
            <div class="">
              <v-icon>mdi-alert</v-icon>
            </div>
            <div class=" pr-4">
              این تست جهت بررسی وضعیت عملکرد و احتمال وجود اختلال عملکرد جنسی بوده و سنجه مناسبی تشخیص نهایی نیست
            </div>
          </div>
        </div>
      </div>
      <div class="textInterpretation mt-10">
        <v-row>
          <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
            <div class="textInterpretationTitle">
              {{ titleInterpretation }}
            </div>
            <!--            <div class="shortInterpretation">-->
            <!--              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.-->
            <!--            </div>-->
            <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">
              <div class="shortInterpretation">
                <div class="titleShortInterpretation">
                  <!--                  ناراحتی و غم-->
                </div>
                <div v-html="item.report.description_for_client_fa"></div>
                <div class="hintShortInterpretation">
                  <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <br>
        <br>
        <br>
      </div>
    </div>
    <br>
    <ConvertToPdf file-name="sexualFunctionInterpretation">
      <template v-slot:pdf_content>
        <div class="charting white text-center" dir="rtl">
          <p style="text-align: center">امتیاز آزمون</p>
          <p style="text-align: center">تشخیص میزان اختلال عملکرد جنسی </p>
          <div class="gauge ">
            <vue-svg-gauge
                :start-angle="-90"
                :end-angle="90"
                :value="score"
                :separator-step="0"
                :min="offset"
                :max="(95 + offset)"
                :gauge-color="gaugeColor"
                :scale-interval="0"
            />

          </div>
          <div class="emojiArea text-center mt-10" style="margin-top: 20px">
            <div class="d-flex justify-center">
              <div class="" style="text-align: center;font-size: 20px" :style="{'color':gaugeColor}">
                {{ score }}
              </div>
              <div class="mr-3 mt-2" :style="{'color':gaugeColor}">
                {{ titleInterpretation }}
              </div>
            </div>
          </div>
          <div class="hintArea " dir="rtl">
            <div class="d-flex justify-space-between  ">
              <div class="">
                <v-icon>mdi-alert</v-icon>
              </div>
              <div class=" pr-4">
                این تست جهت بررسی وضعیت عملکرد و احتمال وجود اختلال عملکرد جنسی بوده و سنجه مناسبی تشخیص نهایی نیست
              </div>
            </div>
          </div>
        </div>
        <div class="textInterpretation mt-10">
          <v-row>
            <v-col cols="12" lg="9 " class="" style="margin: 0px auto" dir="rtl">
              <div class="textInterpretationTitle">
                {{ titleInterpretation }}
              </div>
              <!--            <div class="shortInterpretation">-->
              <!--              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.-->
              <!--            </div>-->
              <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n" dir="rtl">
                <div class="shortInterpretation">
                  <div class="titleShortInterpretation">
                    <!--                  ناراحتی و غم-->
                  </div>
                  <div v-html="item.report.description_for_client_fa"></div>
                  <div class="hintShortInterpretation">
                    <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <br>
          <br>
          <br>
        </div>
      </template>
    </ConvertToPdf>
    <br>
    <PriceBoxBeck v-if="score > 0 && score >= 13"/>
  </div>
</template>

<script>
import {VueSvgGauge} from 'vue-svg-gauge'
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
import ConvertToPdf from "@/components/Test/Main/ConvertPdf";

export default {
  props: {
    report: Object,
  },
  components: {
    ConvertToPdf,
    PriceBoxBeck,
    VueSvgGauge,
  },
  data() {
    return {
      offset: 19,
    }
  },
  computed: {
    score() {
      return this.report.result[0].score.sum;
    },
    gaugeColor() {
      if (this.score >= 0 && this.score <= 23) {
        return '#76d987'
      } else if (this.score >= 24 && this.score <= 48) {
        return '#6ec9b5'
      } else if (this.score >= 49 && this.score <= 73) {
        return '#76bfd9'
      } else {
        return '#7695d9'
      }
    },
    titleInterpretation() {
      if (this.score >= 45) {
        return 'تا حدودی دارای چالش‌هایی در مسائل جنسی خود هستید'
      } else {
        return 'عزت نفس و خودآگاهی جنسی شما نسبتا خوب است'
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
}

.gauge {
  width: 200px;
  margin: 50px auto -20px auto;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>