<template>
  <div class="hint">
    <div class="line " style="margin-bottom: 50px"></div>
    <div class="titleArea mb-3">
      برای بهبود افسردگی چه کاری می‌توان انجام داد؟
    </div>
    <div class="descArea">
      بهترین کار این است که در اولین قدم با یک روان‌درمانگر باتجربه که در زمینه مشاوره فردی تخصص دارد، صحبت کنید. اغلب در یک تا ۳ جلسه اول مشاوره، مشخص می‌شود که شما به جلسات بیشتری نیاز دارید یا خیر. حتما این اولین قدم را بردارید؛ چون افسردگی حتی اگر خفیف باشد، روی عملکرد روزانه و کیفیت روابطتان اثر منفی دارد. هر چه سریع‌تر برای تشخیص و درمان اقدام کنید، روند مشاوره آسان‌تر خواهد بود.
    </div>
    <div class="line " style="margin-top: 50px" ></div>
  </div>
</template>

<script>
export default  {

}
</script>

<style scoped>
.line{
  height: 2px;
  width: 100%;
  background: #000000;
}
.titleArea{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.descArea{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}
</style>