<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting mb-3 white">
        <div class="textInterpretation">
          <div class="textInterpretationTitle text-center">
            {{ titleInterpretation }}
          </div>
        </div>
        <v-row class="mt-5">
          <v-col cols="6" md="6" lg="3">
            <img :class="score <= 5 && 'activeImage'" class="imageEmoji"
                 src="../../../../assets/tests/body_shame/1.png" width="100%" alt="">
            <p class="EmojiTex">بدون شرمساری</p>
          </v-col>
          <v-col cols="6" md="6" lg="3">
            <img :class="score >= 6 && score <= 10 && 'activeImage'" class="imageEmoji"
                 src="../../../../assets/tests/body_shame/2.png" width="100%" alt="">
            <p class="EmojiTex">شرمساری خفیف</p>
          </v-col>
          <v-col cols="6" md="6" lg="3">
            <img :class="score >= 11 && score <= 20 && 'activeImage'" class="imageEmoji"
                 src="../../../../assets/tests/body_shame/3.png" width="100%" alt="">
            <p class="EmojiTex">شرمساری متوسط</p>
          </v-col>
          <v-col cols="6" md="6" lg="3">
            <img :class="score >= 21  && score <= 30 && 'activeImage'" class="imageEmoji"
                 src="../../../../assets/tests/body_shame/4.png" width="100%" alt="">
            <p class="EmojiTex">شرمساری زیاد</p>
          </v-col>
        </v-row>
        <div class="text-center mt-2">
          <ShareNetwork
              v-for="(network,index) in networks"
              :key="index"
              :network="network.title"
              :url="`https://app.simiaroom.com/fa/test/${$route.params.testID}`"
              :title="network.text"
              :description="network.description"
              :quote="network.quote"
              hashtags="simiaroom,simiaservice"
          >
            <v-btn elevation="0" fab class="px-0 ma-1" :class="network.color" small>
              <v-icon class="">{{ network.icon }}</v-icon>
            </v-btn>
          </ShareNetwork>
          <br>
          <v-btn style="width: 160px;background: white !important;" text color="#46b1a1"
                 large
                 :to="`/${$route.params.lang}/test/${$route.params.testID}`" class="mx-2  mt-4">
            انجام مجدد تست
          </v-btn>
          <v-btn color="#46b1a1" class="white--text mt-4" elevation="0" large @click="registerOrTestPanel">
            مقایسه نتیجه تست
            <v-icon class="mr-3">mdi-chart-bar</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="charting white mt-6 text-center">
        <p>امتیاز آزمون</p>
        <p>{{ report.test.title_fa }}</p>
        <div class="gauge ">
          <vue-svg-gauge
              :start-angle="-90"
              :end-angle="90"
              :value="score"
              :separator-step="0"
              :min="report.result[0].min_score"
              :max="report.result[0].max_score"
              :scale-interval="0"
          />
          <div class="gaugeInfo" :style="{'color':gaugeColor}">
            {{ score }}
          </div>
          <div class="px-3 text-left mt-4 d-flex justify-space-between">
            <div>{{ report.result[0].max_score }}</div>
            <div class="pl-1">{{ report.result[0].min_score }}</div>
          </div>

        </div>
        <!--        <div class="hintArea ">-->
        <!--          <div class="d-flex justify-space-between  ">-->
        <!--            <div class="">-->
        <!--              &lt;!&ndash;              <v-icon>mdi-alert</v-icon>&ndash;&gt;-->
        <!--            </div>-->
        <!--            <div class=" pr-4">-->

        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="textInterpretation mt-10">
          <v-row>
            <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
              <!--            <div class="shortInterpretation">-->
              <!--              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.-->
              <!--            </div>-->

              <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">
                <div class="shortInterpretation">
                  <div class="titleShortInterpretation">
                    <!--                  ناراحتی و غم-->
                  </div>
                  <div v-html="item.report.description_for_client_fa"></div>
                  <div class="hintShortInterpretation">
                    <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <br>
          <br>
          <br>
        </div>

      </div>
    </div>
    <br>
    <br>
    <PriceBoxBeck/>
  </div>
</template>

<script>
import {VueSvgGauge} from 'vue-svg-gauge'
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";


export default {
  props: {
    report: Object,
  },
  components: {
    PriceBoxBeck,
    VueSvgGauge,
  },
  data() {
    return {
      networks: [
        {
          title: 'facebook',
          text: '',
          icon: 'mdi-facebook',
          url: '',
          description: 'description',
          quote: 'من در آزمون معتبر شرمساری از بدن شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          hashtags: 'simiaroom',
          color: 'blue white--text'
        },
        {
          title: 'twitter',
          text: 'من در آزمون معتبر شرمساری از بدن شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          icon: 'mdi-twitter',
          url: '',
          description: 'description',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'blue white--text lighten-1'
        },
        {
          title: 'telegram',
          text: '',
          icon: 'mdi-send',
          url: '',
          description: 'من در آزمون معتبر شرمساری از بدن شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'blue white--text darken-1'
        },
        {
          title: 'whatsapp',
          text: '',
          icon: 'mdi-whatsapp',
          url: '',
          description: 'من در آزمون معتبر شرمساری از بدن شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'green white--text'
        },
      ]
    }
  },
  computed: {
    offset() {
      return this.report.result[0].min_score;
    },
    score() {
      return this.report.result[0].score.sum;
    },
    titleInterpretation() {
      if (this.score >= 0 && this.score <= 5) {
        return 'بدون شرمساری'
      } else if (this.score >= 6 && this.score <= 10) {
        return 'شرمساری خفیف'
      } else if (this.score >= 11 && this.score <= 20) {
        return 'شرمساری متوسط'
      } else {
        return 'شرمساری زیاد'
      }
    },

  },
  methods: {
    registerOrTestPanel() {
      this.$emit('registerOrTestPanel')
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }
}

.imageEmoji {
  width: 100%;
  filter: grayscale(1);
  opacity: 0.5;
}

.EmojiTex {
  text-align: center;
  top: 10px;
}

.activeImage {
  filter: none !important;
  opacity: 1 !important;


}

.shortInterpretation div p img {
  display: none !important;
  width: 100% !important;
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
}

.gauge {
  width: 200px;
  margin: 50px auto -20px auto;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>