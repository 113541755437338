<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center">
        <p>امتیاز آزمون</p>
        <p>{{ report.test.title_fa }}</p>
        <div class="gauge " v-if="report.test.graph_type == 'Gauge'">
          <vue-svg-gauge
              :start-angle="-90"
              :end-angle="90"
              :value="score"
              :separator-step="0"
              :min="offset"
              :max="report.result[0].max_score"
              :gauge-color="gaugeColor"
              :scale-interval="0"
          />
          <div class="gaugeInfo" :style="{'color':gaugeColor}">
            {{ score }}
          </div>
          <div class="px-3 text-left mt-4 d-flex justify-space-between">
            <div>{{ report.result[0].max_score }}</div>
            <div class="pl-1">{{ report.result[0].min_score }}</div>
          </div>
        </div>
        <div class="bar " v-if="report.test.graph_type == 'Bar'">
          <vue-apex-charts type="bar" :height="$vuetify.breakpoint.mdAndDown? 300:'auto'" :options="chartOptions"
                           :series="series"
                           v-if="zonesScore.length > 0 && zonesName.length > 0 && maxScore != 0"></vue-apex-charts>
        </div>
        <div class="radar " v-if="report.test.graph_type == 'Radar'">
          <vue-apex-charts type="radar" :height="$vuetify.breakpoint.mdAndDown ? 300 : 600" :options="RadarChartOptions"
                           :series="series"
                           v-if="zonesScore.length > 0 && zonesName.length > 0 && maxScore != 0"></vue-apex-charts>
          <v-row v-if="$vuetify.breakpoint.mdAndDown">
            <v-col class="d-flex justify-space-between" cols="12" v-for="(item , i) in zonesName" :key="i">
              <div>
                {{ (i + 1) }} - {{ item }}
              </div>
              <div class="badge_score">
                {{ zonesScore[i] }}
              </div>
            </v-col>
          </v-row>
        </div>


        <v-btn color="#46b1a1" class="white--text mt-12" elevation="0" @click="registerOrTestPanel">
          مقایسه نتیجه تست
          <v-icon class="mr-3">mdi-chart-bar</v-icon>
        </v-btn>
        <div class="textInterpretation mt-10">
          <v-row>
            <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
              <!--            <div class="shortInterpretation">-->
              <!--              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.-->
              <!--            </div>-->
              <div class="singleInterpretation mb-15" v-for="(item , n) in report.reports" :key="n" :id="item.id">
                <img :src="(assetUrl + item.report.mobile_image)" width="100%" alt=""
                     v-if="$vuetify.breakpoint.mdAndDown">
                <img :src="(assetUrl + item.report.desktop_image)" width="100%" alt=""
                     v-if="$vuetify.breakpoint.mdAndUp">
                <div class="shortInterpretation">
                  <div class="titleShortInterpretation">
                    <!--                  ناراحتی و غم-->
                  </div>
                  <div v-html="item.report.description_for_client_fa"></div>
                  <div class="hintShortInterpretation">
                    <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <br>
          <br>
          <br>
        </div>
      </div>
      <div class="mt-10 marketingSection" v-if="contents.carousel && contents.carousel.length !== 0"
      >
        <div class="titleSection">
          پیشنهادات سیمیاروم
        </div>
        <div class="sliderArea advance">
          <hooper

              :rtl="true"
              :itemsToShow="$vuetify.breakpoint.mdAndUp ?  4 : 1"
              :autoPlay="true"
              :playSpeed="5000"
              style="min-height:400px;"
              :infiniteScroll="true"
              :wheel-control="false"
          >
            <slide v-for="(item , i) in contents.carousel" :key="i">
              <a target="_blank"
                 class="pa-2"
                 :href="item.link">
                <div class="singleSlider">
                  <div class="imageArea">
                    <img :src="assetUrl+item.image" width="100%" alt="">
                  </div>
                  <div class="titleSlider">
                    {{ item.title }}
                  </div>
                  <div class="descSlider">
                    {{ item.description }}
                  </div>
                </div>
              </a>
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>

          </hooper>

        </div>
      </div>
      <div class="fixedItemArea mb-15 mt-10">
        <v-row>
          <v-col class="px-2" cols="12" :lg="content.size" v-for="(content , i) in contents.fixed_item" :key="i">
            <div class="fixedItem">
              <a :href="content.link">
                <div>
                  <img :src="assetUrl+content.image" alt="" width="100%">
                  <div class="titleFixedItem font-weight-bold">
                    {{ content.title }}
                  </div>
                  <div class="descFixItem">
                    {{ content.description }}

                  </div>
                </div>
              </a>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <br>
    <template v-if="contents.banner">
      <BannerAdvanceTemplate :i="0" :content="contents.banner[0]"/>
    </template>
    <!--    <div class="mt-10" v-for="(item , i) in contents.banner" :key="i">-->
    <!--    </div>-->

  </div>
</template>

<script>
/*eslint-disable*/
import {VueSvgGauge} from 'vue-svg-gauge'
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
import {Hooper, Slide, Pagination as HooperPagination} from "hooper";
import BannerAdvanceTemplate from "@/components/Test/Main/BannerAdvanceTemplate";
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    report: Object,
  },
  components: {
    BannerAdvanceTemplate,
    PriceBoxBeck,
    VueSvgGauge,
    Hooper,
    VueApexCharts,
    Slide,
    HooperPagination
  },
  data() {
    return {
      contents: {
        carousel: null,
        all: null,
        fixed_item: null,
        banner: null,
      },
      zonesName: [],
      zoneNumbered: [],
      maxScore: null,
      zonesScore: [],

    }
  },


  mounted() {
    this.contents.all = this.prepareContent()
    this.prepareContentBasedOnType()
    if (this.report.test.graph_type == 'Bar' || this.report.test.graph_type == 'Radar') {
      this.prepareChartData()
    }
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.chartOptions.xaxis.offsetY = 70
      this.chartOptions.xaxis.offsetX = -28
    }
  },
  methods: {
    prepareChartData() {
      this.report.result.forEach((item, i) => {
        if (item.name != 'Main Zone' && item.name && !item.hasOwnProperty('variable')) {
          this.zonesName.push(item.name)
          if (item.score.hasOwnProperty('average')) {
            this.zonesScore.push(item.score.average)
          } else {
            if (this.$route.params.testID == 33) {
              this.zonesScore.push(Math.round((item.score.sum / 5)))
              this.zoneNumbered.push(i)
            } else {
              this.zonesScore.push(item.score.sum)
            }
          }
          if (item.max_score > this.maxScore && item.max_score) {
            // console.log(item.max_score)
            this.maxScore = item.max_score
          }
        }
      })
    },
    prepareContentBasedOnType() {
      this.contents.carousel = this.contents.all.filter((item, index, array) => {
        return item.display_type === 'Carousel'
      })
      this.contents.fixed_item = this.contents.all.filter((item, index, array) => {
        return item.display_type === 'Fixed_Item'
      })
      this.contents.banner = this.contents.all.filter((item, index, array) => {
        return item.display_type === 'Banner'
      })
      console.log(this.contents.banner)
    },
    prepareContent() {
      let contents = []
      let hasBanner = false
      this.report.reports.forEach((item) => {
        if (item.report.contents) {
          item.report.contents.forEach((report) => {
            if (report.display_type == 'Banner') {
              hasBanner = true
            }
            contents.push(report)
          })
        }
      })
      if (this.report.test.contents) {
        this.report.test.contents.forEach((item) => {
          if (hasBanner) {
            if (item.display_type != 'Banner') {
              contents.push(item)
            }
          } else {
            contents.push(item)
          }

        })
      }


      return contents.filter((item, index, array) => {
        return array.findIndex((element) => element.id === item.id) === index;
      })
    },

    registerOrTestPanel() {
      this.$emit('registerOrTestPanel')
    }
  },
  computed: {
    series() {
      return [{
        name: 'امتیاز',
        data: this.zonesScore
      }]
    },
    chartOptions() {
      return {
        chart: {
          // height: 200,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '30%',
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 1
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -35,
            // rotateAlways: true,
            hideOverlappingLabels: false,
            // showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'inherit',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },

          },
          categories: this.zonesName
        },
        yaxis: {
          min: 0,
          max: this.maxScore,
          forceNiceScale: true,
          decimalsInFloat: false,
          title: {
            text: '',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        },
      }
    },
    RadarChartOptions() {
      return {
        chart: {
          // height: 200,
          type: 'radar',
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '30%',
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 1
        },
        grid: {
          row: {
            // colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -35,
            // rotateAlways: true,
            hideOverlappingLabels: false,
            // showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'inherit',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: 0,
            offsetY: 0,

          },
          categories: this.$vuetify.breakpoint.mdAndUp ? this.zonesName : this.zoneNumbered
        },
        yaxis: {
          show:false,
          min: 0,
          max: this.$route.params.testID == 33 ? 10 : this.maxScore,
          forceNiceScale: true,
          decimalsInFloat: false,
          title: {
            text: '',
          },
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     shade: 'light',
        //     type: "horizontal",
        //     shadeIntensity: 0.25,
        //     gradientToColors: undefined,
        //     inverseColors: true,
        //     opacityFrom: 0.85,
        //     opacityTo: 0.85,
        //     stops: [50, 0, 100]
        //   },
        // },
      }
    },
    assetUrl() {
      return this.$store.getters.testAssetUrl
    },
    offset() {
      return this.report.result[0].min_score;
    },
    score() {
      return this.report.result[0].score.sum;
    },
    gaugeColor() {
      if (this.score >= 0 && this.score <= 15) {
        return '#76d987'
      } else if (this.score >= 16 && this.score <= 32) {
        return '#6ec9b5'
      } else if (this.score >= 33 && this.score <= 48) {
        return '#76bfd9'
      } else {
        return '#7695d9'
      }
    },

  }
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }

  .bar {
    width: 100% !important;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }
}

.badge_score {
  background-color: #ededed;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.descFixItem {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 100px;
  font-size: 12px;
  line-height: 18pt;
  -webkit-line-clamp: 4;
}

.titleFixedItem {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  -webkit-line-clamp: 2;
  height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


a {
  text-decoration: none !important;
  color: black !important;
}

.singleSlider {
  margin: 10px;
  background-color: white;
  padding: 10px;
  /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.fixedItem {
  background-color: white;
  padding: 10px;
  /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.fixedItem img {
  border-radius: 10px 10px 0px 0px;
}

.titleSlider {
  font-size: 15px;
  font-weight: bolder;
  line-height: 20pt;
  height: 52px;
  overflow: hidden;
}

.descSlider {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 100px;
  font-size: 12px;
  line-height: 18pt;
  -webkit-line-clamp: 4;
}

.singleSlider .imageArea img {
  border-radius: 10px 10px 0px 0px;
}


.titleSection {
  font-size: 20px;
  text-align: center;
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
}

.gauge {
  width: 200px;
  margin: 50px auto -20px auto;
  position: relative;
}

.bar {
  width: 80%;
  margin: 50px auto -20px auto;
  position: relative;
}

.radar {
  width: 100%;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>