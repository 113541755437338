<template>
  <div class="d">
    <v-row class="mb-15 fill-height">
      <v-col cols="12" lg="8" class="fill-height   ">
        <div  :id="`contentArea${i}`" class="contentFixedItem   d-flex flex-column pt-2  fill-height  justify-center   text-right ">
          <div class="">
            <div class="titleContent">
              {{ content.title }}
            </div>
            <div class="descContent">
              {{ content.description }}
            </div>
            <a :href="content.link">
              <div class="actionContent mt-4">
                کلیک کنید
              </div>
            </a>
          </div>
        </div>
      </v-col>
      <v-col c cols="12" lg="4" class="imageContent  " v-if="$vuetify.breakpoint.mdAndUp">
        <img :src="assetUrl+content.image" :id="`imageArea${i}`" width="100%" alt="">
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import RegisteringSection from "@/components/Test/Main/RegisteringSection";

export default {
  components: {RegisteringSection},
  props: {
    content: Object,
    i: Number,
  },
  data() {
    return {
      customerType: 'new',
      switchLock: false,
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndUp){
      document.getElementById(`imageArea${this.i}`).onload = function () {
        // Get the height of the image
        var imageHeight = this.height;
        console.log(this.height)

        // Set the height of the target div
        document.getElementById('contentArea0').style.height = imageHeight + 'px';
      };
    }
  },
  computed: {
    assetUrl() {
      return this.$store.getters.testAssetUrl
    },
  },
  methods: {
    gotoPackage(id) {
      this.$router.push(`/${this.$route.params.lang}/onboarding?package_id=${id}`)
    },
    switcherHandlerCustomer() {
      if (!this.switchLock) {
        this.switchLock = true
        if (this.customerType === 'new') {
          gsap.fromTo('.switch-pointer', {right: '5px'}, {left: '5px', right: 'auto', duration: .15}).then(() => {
            this.customerType = 'old'
            this.switchLock = false
          })
        } else {
          gsap.to('.oldCustomer', {display: 'none'})
          gsap.fromTo('.switch-pointer', {left: '5px'}, {right: '5px', left: 'auto', duration: .15}).then(() => {
            this.switchLock = false
            this.customerType = 'new'
          })
        }
      }
    }
  }
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 800px) {
  .contentFixedItem {
    margin-right: 0px !important;
    text-align: center !important;
    border-top: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    padding: 30px 10px;
  }

  .descContent {
    width: 90% !important;
    margin: 0px auto;
  }

  .actionContent {
    margin: 0px auto;
  }

}

.contentFixedItem {
  margin-right: 200px;
}

.titleContent {
  font-size: 20px;
  color: #39b787;
  margin-bottom: 10px;
}

.descContent {
  width: 40%;
  line-height: 25pt;
}

.imageContent {
  /*padding-left: 200px;*/
}

.actionContent {
  fill: #fff;
  color: #fff;
  background-color: #39b787;
  width: 200px;
  font-size: 13px;
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
}
</style>