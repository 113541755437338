<template>
  <div>
    <div class="text-center my-5">
      <v-btn color="#46b1a1" class="white--text" elevation="0" @click="downloadPDF">
        {{title}}
        <v-icon class="mx-1">mdi-download-box</v-icon>
      </v-btn>
    </div>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1000"
        :filename="fileName"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="pdfInterpretation"
    >
      <section slot="pdf-content">
        <center>
          <img src="../../../assets/simialogopng.png" width="100px" alt="">
        </center>
        <slot name="pdf_content"></slot>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {VueHtml2pdf},
  props: {
    fileName: String,
    title:{
      default:'دانلود فایل تفسیر'
    }
  },
  methods:{
    downloadPDF(){
      this.$refs.pdfInterpretation.generatePdf()
    }
  }
}
</script>

<style scoped>

</style>