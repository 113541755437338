<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center">
        <p>امتیاز آزمون</p>
        <p>{{ report.test.title_fa }}</p>
        <div class="gauge ">
          <vue-apex-charts type="bar" :height="$vuetify.breakpoint.mdAndDown? 400 :'auto'" :options="chartOptions"
                           :series="series"
                           v-if="zonesScore.length > 0 && zonesName.length > 0 && maxScore != 0"></vue-apex-charts>
          <v-btn color="#46b1a1" style="margin-top: -100px" v-if="$vuetify.breakpoint.mdAndUp"  class="white--text" elevation="0" large @click="registerOrTestPanel">
            مقایسه نتیجه تست
            <v-icon class="mr-3">mdi-chart-bar</v-icon>
          </v-btn>
          <v-row dense class="pr-5" style="margin-top: -20px" v-if="$vuetify.breakpoint.mdAndDown">
            <v-col cols="6" class="text-right"  v-for="(zone , i ) in zonesName" :key="i" style="font-size: 10px">
              {{ (i + 1) }} - {{ zone }}
            </v-col>
          </v-row>
        </div>
        <!--        <div class="hintArea ">-->
        <!--          <div class="d-flex justify-space-between  ">-->
        <!--            <div class="">-->
        <!--              &lt;!&ndash;              <v-icon>mdi-alert</v-icon>&ndash;&gt;-->
        <!--            </div>-->
        <!--            <div class=" pr-4">-->
        <!--                -->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
      <div class="textInterpretation mt-10">
        <div class="text-center">
          <v-btn color="#46b1a1" v-if="$vuetify.breakpoint.mdAndDown"  class="white--text" elevation="0"  @click="registerOrTestPanel">
            مقایسه نتیجه تست
            <v-icon class="mr-3">mdi-chart-bar</v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
            <!--            <div class="shortInterpretation">-->
            <!--              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.-->
            <!--            </div>-->
            <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n" :id="item.id">
              <div class="shortInterpretation">
                <div class="titleShortInterpretation">
                  <!--                  ناراحتی و غم-->
                </div>
                <div v-html="item.report.description_for_client_fa"></div>
                <div class="hintShortInterpretation">
                  <!--                  احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.-->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

      </div>
      <SuggestTherapistsYEMSQ/>

    </div>
    <br>
    <PriceBoxBeck/>

  </div>
</template>

<script>
import {VueSvgGauge} from 'vue-svg-gauge'
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
import ConvertToPdf from "@/components/Test/Main/ConvertPdf";
import VueApexCharts from "vue-apexcharts";
import SuggestTherapistsYEMSQ from "@/components/Test/Beck/SuggestTherapistsYEMSQ";
/*eslint-disable*/
export default {
  props: {
    report: Object,
  },
  components: {
    SuggestTherapistsYEMSQ,
    ConvertToPdf,
    PriceBoxBeck,
    VueSvgGauge,
    VueApexCharts
  },
  data() {
    return {
      zonesName: [],
      zonesScore: [],
      zonesNameMobile: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      maxScore: 30,
      colors: [],
      textColors:[],
    }
  },
  methods: {
    registerOrTestPanel(){
      this.$emit('registerOrTestPanel')
    },
    prepareChartInfo() {
      this.report.result.forEach(item => {
        if (item.name != 'Main Zone' && item.name && !item.hasOwnProperty('variable')) {
          this.zonesName.push(item.name)
          if (item.score.hasOwnProperty('average')) {
            this.zonesScore.push(item.score.average)
          } else {
            this.zonesScore.push(item.score.sum)
          }
          if (item.max_score > this.maxScore && item.max_score) {
            // console.log(item.max_score)
            this.maxScore = item.max_score
          }
        }
      })
      this.zonesScore.forEach(item => {
        if (item <= 5) {
          this.colors.push('#568b7c')
          this.textColors.push('#000')
        } else if (item >= 6 && item <= 15) {
          this.colors.push('#8bf1d5')
          this.textColors.push('#000')
        } else if (item >= 16 && item <= 20) {
          this.colors.push('#f8194d')
          this.textColors.push('#fff')
        } else if (item >= 21 && item <= 30) {
          this.colors.push('#b50c34')
          this.textColors.push('#fff')
        }
      })
      // if (item <= 5) {
      //   this.colors.push('#afecf3')
      // } else if (item >=6 && item <= 15){
      //   this.colors.push('#4fe2f3')
      // }else if (item >=16 && item <= 20){
      //   this.colors.push('#21acbc')
      // }else if (item >= 21 && item <= 30) {
      //   this.colors.push('#0f8391')
      // }

      // console.log(this.zonesName, this.zonesScore)
      // alert(this.maxScores)
    }
  },
  mounted() {
    this.prepareChartInfo();
  },
  computed: {
    series() {
      return [{
        name: '',
        data: this.zonesScore
      }]
    },
    chartOptions() {
      return {
        chart: {
          height: 200,
          type: 'bar',
        },
        legend: {
          show: false
        },

        colors: this.colors,
        plotOptions: {
          bar: {
            distributed: true,
            // borderRadius: this.$vuetify.breakpoint.mdAndUp ? 10 : 0,
            columnWidth: '45%',
            horizontal: !this.$vuetify.breakpoint.mdAndUp,

          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -90,
            color:'#fff',
            // rotateAlways: true,
            hideOverlappingLabels: false,
            // showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'inherit',
              fontWeight: 400,
              colors:this.textColors,
              cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: this.$vuetify.breakpoint.mdAndUp ?  -1 : 0,
            offsetY: this.$vuetify.breakpoint.mdAndUp ? -20 : 0,

          },
          categories: this.$vuetify.breakpoint.mdAndUp ? this.zonesName : this.zonesNameMobile
        },
        yaxis: {
          min: 0,
          max: this.maxScore,
          forceNiceScale: true,
          decimalsInFloat: false,
          title: {
            text: '',
          },
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     // shade: 'light',
        //     type: "vertical",
        //     shadeIntensity: 0.25,
        //     gradientToColors: ,
        //     inverseColors: true,
        //     opacityFrom: 0.85,
        //     opacityTo: 0.85,
        //     stops: [10, 0, 100]
        //   },
        // },
      }
    },
    score() {
      return this.report.result[0].score.sum;
    },

  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }

  .gauge {
    width: 100%;
    margin: 0px auto 0px auto !important;
    position: relative;
  }

  .charting {
    padding: 40px 0px !important;
  }
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
}

.gauge {
  width: 100%;
  margin: 50px auto -20px auto;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>