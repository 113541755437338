<template>
  <div class="priceBox">
    <div class="fill-height ">
      <div class="contentArea">
        <div class="titleArea  text-center mb-4">
          پیشنهاد سیمیاروم
        </div>
        <div class="descArea text-center">
          <!--          جلسه پیش‌مشاوره، برای تحلیل این تست و معرفی بهترین راه برای حل مشکل شما، با کمترین هزینه‌ می‌باشد-->
        </div>
      </div>
      <div class="solutionsArea mt-12">
        <v-row v-if="Object.keys(contents).length != 0">
          <v-col cols="12" lg="4" class="">
            <p class="titleSolutions">فیلم‌های پیشنهادی</p>
            <div class="SliderBlogArea ">
              <div class="actionSlider">
                <div @click="nextItem(1)" class="nextBtn ml-1 d-flex flex-column justify-center align-center"
                     style="position: relative;cursor: pointer">
                  <div class="sliderActionBtn"></div>
                  <v-icon class="sliderActionIcon" color="white">mdi-menu-right</v-icon>
                </div>
                <div @click="prevItem(1)" class="prevBtn d-flex flex-column justify-center align-center"
                     style="position: relative;cursor: pointer">
                  <div class="sliderActionBtn"></div>
                  <v-icon class="sliderActionIcon" color="white">mdi-menu-left</v-icon>
                </div>
              </div>
              <hooper ref="blogSlider1" rtl :itemsToShow="1" :wheel-control="false" :centerMode="true" pagination="no"
                      infinite-scroll
                      style="height: 300px;width: 100%">
                <slide class="" v-for="(movie , i) in contents.movie" :key="i">
                  <div class="blogPostSliderItem"
                       :style="{background:`url(${movie.image})`}"
                  >
                    <div class="blackCover"></div>
                    <div class="titleBlogSliderItem">
                      {{ movie.title }}
                    </div>
                  </div>
                </slide>
              </hooper>
            </div>
          </v-col>
          <v-col cols="12" lg="4" class="">
            <p class="titleSolutions">کتاب‌های پیشنهادی</p>
            <div class="SliderBlogArea ">
              <div class="actionSlider">
                <div @click="nextItem(2)" class="nextBtn ml-1 d-flex flex-column justify-center align-center"
                     style="position: relative;cursor: pointer">
                  <div class="sliderActionBtn"></div>
                  <v-icon class="sliderActionIcon" color="white">mdi-menu-right</v-icon>
                </div>
                <div @click="prevItem(2)" class="prevBtn d-flex flex-column justify-center align-center"
                     style="position: relative;cursor: pointer">
                  <div class="sliderActionBtn"></div>
                  <v-icon class="sliderActionIcon" color="white">mdi-menu-left</v-icon>
                </div>
              </div>
              <hooper ref="blogSlider2" rtl :itemsToShow="1" :wheel-control="false" :centerMode="true" pagination="no"
                      infinite-scroll
                      style="height: 300px;width: 100%">
                <slide class="" v-for="(book , n) in contents.book_one" :key="n">
                  <div class="blogPostSliderItem"
                       style="background-repeat: no-repeat !important;background-size: cover !important;"
                       :style="{background:`url(${book.image})`}"
                  >
                    <div class="blackCover"></div>
                    <div class="titleBlogSliderItem ">
                      {{ book.title }}
                    </div>
                  </div>
                </slide>
              </hooper>
            </div>
          </v-col>
          <v-col cols="12" lg="4" class="">
            <p class="titleSolutions">کتابچه‌های آموزشی</p>
            <div class="SliderBlogArea ">
              <div class="actionSlider">
                <div @click="nextItem(3)" class="nextBtn ml-1 d-flex flex-column justify-center align-center"
                     style="position: relative;cursor: pointer">
                  <div class="sliderActionBtn"></div>
                  <v-icon class="sliderActionIcon" color="white">mdi-menu-right</v-icon>
                </div>
                <div @click="prevItem(3)" class="prevBtn d-flex flex-column justify-center align-center"
                     style="position: relative;cursor: pointer">
                  <div class="sliderActionBtn"></div>
                  <v-icon class="sliderActionIcon" color="white">mdi-menu-left</v-icon>
                </div>
              </div>
              <hooper ref="blogSlider3" rtl :itemsToShow="1" :wheel-control="false" :centerMode="true" pagination="no"
                      infinite-scroll
                      style="height: 300px;width: 100%">
                <slide class="" v-for="(book , n) in contents.book_two" :key="n">
                  <a :href="book.href" target="_blank">
                    <div class="blogPostSliderItem"
                         style="background-repeat: no-repeat !important;background-size: cover !important;"
                         :style="{background:`url(${book.image})`}"
                    >
                      <div class="blackCover"></div>
                      <div class="titleBlogSliderItem ">
                        {{ book.title }}
                      </div>
                      <div class="badgeCategory">
                        <v-btn color="#46b1a1" elevation="0" small class="white--text">
                          دانلود
                          <v-icon size="15">mdi-download</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </a>
                </slide>
              </hooper>
            </div>
          </v-col>

        </v-row>
      </div>

    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import {Hooper, Slide} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'SolutionsProvidedBeck',
  data() {
    return {
      customerType: 'new',
      switchLock: false,
      contents: {}
    }
  },
  components: {
    Hooper,
    Slide
  },
  created() {
    this.prepareBookAndMovies()
  },
  methods: {
    gotoPackage(id) {
      this.$router.push(`/${this.$route.params.lang}/onboarding?package_id=${id}`)
    },
    switcherHandlerCustomer() {
      if (!this.switchLock) {
        this.switchLock = true
        if (this.customerType === 'new') {
          gsap.fromTo('.switch-pointer', {right: '5px'}, {left: '5px', right: 'auto', duration: .15}).then(() => {
            this.customerType = 'old'
            this.switchLock = false
          })
        } else {
          gsap.to('.oldCustomer', {display: 'none'})
          gsap.fromTo('.switch-pointer', {left: '5px'}, {right: '5px', left: 'auto', duration: .15}).then(() => {
            this.switchLock = false
            this.customerType = 'new'
          })
        }
      }
    },
    nextItem(ref) {
      if (ref == 1) {
        this.$refs.blogSlider1.slideNext()
      } else if (ref == 2) {
        this.$refs.blogSlider2.slideNext()
      } else {
        this.$refs.blogSlider3.slideNext()
      }
    },
    prevItem(ref) {
      if (ref == 1) {
        this.$refs.blogSlider1.slidePrev()
      } else if (ref == 2) {
        this.$refs.blogSlider2.slidePrev()
      } else {
        this.$refs.blogSlider3.slidePrev()
      }
    },
    prepareBookAndMovies() {
      if (this.score >= 0 && this.score <= 13) {
        this.contents.interpretation = "https://simiaroom.com/wp-content/beck/BDI%20R1%20(1).pdf"
      } else if (this.score >= 14 && this.score <= 19) {
        this.contents.interpretation = "https://simiaroom.com/wp-content/beck/BDI%20R1%20(1).pdf"
      } else if (this.score >= 20 && this.score <= 28) {
        this.contents.interpretation = "https://simiaroom.com/wp-content/beck/BDI%20R1%20(1).pdf"
      } else {
        this.contents.interpretation = "https://simiaroom.com/wp-content/beck/BDI%20R1%20(1).pdf"
      }

      this.contents.movie = [
        {
          title: 'فیلم سینمایی فارست گامپ',
          image: require('../../../assets/beck/Artboard9.webp')
        },
        {
          title: 'فیلم سینمایی هکتور در جستجوی خوشبختی',
          image: require('../../../assets/beck/Artboard8.webp')
        },
        {
          title: 'فیلم سینمایی انکانتو',
          image: require('../../../assets/beck/Artboard7.webp')
        },
        {
          title: 'فیلم سینمایی در جست‌و‌جوی خوشبختی',
          image: require('../../../assets/beck/Artboard6.webp')
        },
      ]
      this.contents.book_one = [
        {
          title: 'Hector and the Secrets of Love - francois lelord',
          image: require('../../../assets/beck/Artboard2.webp')
        },
        {
          title: 'The Happiness Trap -  Russ Harris and Steven C. Hayes',
          image: require('../../../assets/beck/Artboard1.webp')
        },
        {
          title: 'Hector and the Search for Happiness - francois lelord',
          image: require('../../../assets/beck/Artboard3.webp')
        },
        {
          title: 'Hector and the Search for Lost Time: A Novel - francois lelord',
          image: require('../../../assets/beck/Artboard4.webp')
        },
        {
          title: 'Authentic Happiness – Martin E.P. Seligman',
          image: require('../../../assets/beck/Artboard5.webp')
        },
      ]
      this.contents.book_two = [
        {
          title: 'self Help Top Tips Quality Of Life Booklet',
          image: require('../../../assets/beck/book1.webp'),
          href: "https://simiaroom.com/wp-content/beck/3.%20self%20Help_%20Top-Tips-Quality-Of-Life-Booklet.pdf"
        },
        {
          title: 'کتابچه درمان افسردگی',
          image: require('../../../assets/beck/book2.webp'),
          href: "https://simiaroom.com/wp-content/beck/3. Self Help_ Persian.pdf"
        },
        {
          title: 'Self Help Depression for partners guide',
          image: require('../../../assets/beck/Artboard611.jpg'),
          href: "https://simiaroom.com/wp-content/beck/3. Self Help_Depression for partners guide.pdf"
        },
      ]
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 1200px) {
  .priceBox {
    height: auto !important;
    padding: 20px;
  }

  .contentArea {
    width: 100% !important;
  }

  .solutionsArea {
    width: 100% !important;
  }

  .SliderBlogArea {
    margin-bottom: 20px !important;
  }
}

.titleSolutions {
  text-align: center;
}

.descBook {
  font-size: 15px;
}

.titleBook {
  font-size: 18px;
  margin-bottom: 10px;
}

.titleBookSection {
  text-align: right;

}

.imageBookSection {
  height: 100%;
  width: 100px;
}

.imageBookSection img {
  width: 100%;
  height: 100%;
}

.BookImage {
  height: 120px;
  overflow: hidden;
  border-radius: 10px;
  color: black;
  border: 1px solid grey;
}

.solutionsArea {
  width: 50%;
  margin: 0px auto;
}

.actionSlider {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 10;
  display: flex;

}

.sliderActionBtn {
  background: white;
  border-radius: 50%;
  opacity: 0.6;
  width: 26px;
  height: 26px;
  position: absolute;
}

.sliderActionIcon {
  /*position: absolute;*/
  /*top: 2px;*/
}

.solutionListViewItem {
  margin-bottom: 10px;
}

.solutionListViewTitle {
  font-size: 20px;
  border: 1px solid grey;
  width: auto;
  display: inline-block;
  padding: 0px 10px;
}

.solutionListViewDescription {
  font-size: 15px;
  margin-top: 5px;
}

.solutionListViewAction {
  margin-top: 10px;
}

.blogPostSliderItem {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-size: cover !important;
  background-position: center !important;
}

.blogPostSliderItem img {
  width: 100%;
  height: 100%;
}

.SliderBlogArea {
  position: relative;
  /*box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;*/
  box-shadow: 0 15px 20px -5px rgba(0, 0, 0, .3);
  border-radius: 15px;
  width: 211px;
  height: 300px;
  background-color: transparent;
  margin: 0px auto;
}

.contentArea {
  margin-top: 30px;
}

.priceBox {
  height: 600px;
  background: url("../../../assets/tests/backPriceBox.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  /*background: skyblue;*/
  /*color: white !important;*/
  position: relative;
  padding-top: 25px;
}

.titleArea {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.contentArea {
  width: 50%;
  margin: 0px auto;
}

.blackCover {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: black;
  opacity: 0.2;
  transition: all 0.3s;
}

.blackCover:hover {
  /*opacity: 0.3;*/
  transition: all 0.3s;
}


.titleBlogSliderItem {
  position: absolute;
  bottom: 0px;
  color: white;
  right: 0px;
  left: 0px;
  /*font-size: 20px;*/
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 15px;
  text-align: center;

}

.badgeCategory {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>