<template>
  <div>
    <div class="emojiArea text-center pt-12 ">
      <v-row>
        <v-col cols="6" lg="3" v-for="(item , i ) in renderEmojis" :key="i">
          <div class="singleEmoji">
            <img :src="item.img" alt="" :class="!needToDeActive(item.min,item.max) && 'deActivePicture' ">
            <p class="mt-2 emojiTitle">{{ item.title }}</p>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="mt-5">
      <ShareNetwork
          v-for="(network,index) in networks"
          :key="index"
          :network="network.title"
          :url="`https://simiaroom.com/beck-result/?gender=other&slide=${slideHandler}`"
          :title="network.text"
          :description="network.description"
          :quote="network.quote"
          hashtags="simiaroom,simiaservice"
      >
        <v-btn elevation="0" fab class="px-0 ma-1" :class="network.color" small>
          <v-icon class="">{{ network.icon }}</v-icon>
        </v-btn>
      </ShareNetwork>
      <p class="mt-5 mb-6">
        نتیجه را با دیگران به اشتراک بگذارید و دعوتشان کنید آن‌ها هم در آزمون افسردگی شرکت کنند
      </p>
    </div>
    <div class="text-center mt-4 ">
      <br>
      <div class="d-flex justify-center " :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">
        <div>
          <v-btn style="width: 160px;background: white !important;" text color="#46b1a1"
                 x-large
                 :to="`/${$route.params.lang}/test/${$route.params.testID}`" class="mx-2 ">
            انجام مجدد تست
          </v-btn>
        </div>
        <div>
          <a :href="interpretationLink">
            <v-btn color="#46b1a1" class="white--text" elevation="0" x-large>
              نسخه کامل تفسیر
              <v-icon class="mr-3">mdi-download-box</v-icon>
            </v-btn>
          </a>
          <!--          <ConvertToPdf file-name="beckTest">-->
          <!--            <template v-slot:pdf_content>-->
          <!--              <div class="contentAreaPdf" dir="rtl">-->
          <!--                <div class="charting white text-center">-->
          <!--                  <center>-->
          <!--                    <p class="text-center">امتیاز آزمون</p>-->
          <!--                    <p class="text-center">تشخیص میزان افسردگی</p>-->
          <!--                  </center>-->
          <!--                  <div class="gauge ">-->
          <!--                    <vue-svg-gauge-->
          <!--                        :start-angle="-90"-->
          <!--                        :end-angle="90"-->
          <!--                        :value="score"-->
          <!--                        :separator-step="0"-->
          <!--                        :min="report.result[0].min_score"-->
          <!--                        :max="report.result[0].max_score"-->
          <!--                        :gauge-color="gaugeColor"-->
          <!--                        :scale-interval="0"-->
          <!--                    />-->
          <!--                  </div>-->
          <!--                  <div class="emojiArea text-center mt-10">-->
          <!--                    <div class="d-flex justify-center">-->
          <!--                      <div class="" :style="{'color':gaugeColor}">-->
          <!--                        {{ score }}-->
          <!--                      </div>-->
          <!--                      <div>-->
          <!--                        <img :src='emojiInterpretations' alt="">-->
          <!--                      </div>-->
          <!--                      <div>-->
          <!--                      </div>-->
          <!--                      <div class="mr-3 mt-1" :style="{'color':gaugeColor}">-->
          <!--                        {{ titleInterpretation }}-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div class="hintArea " dir="rtl">-->
          <!--                    <div class="d-flex justify-space-between  ">-->
          <!--                      <div class="">-->
          <!--                        <v-icon>mdi-alert</v-icon>-->
          <!--                      </div>-->
          <!--                      <div class=" pr-4">-->
          <!--                        تست افسردگی بک شامل ۲۱ سوال است که برای سنجش بازخورد‌ها و نشانه‌های افراد مبتلا به افسردگی طراحی-->
          <!--                        شده-->
          <!--                        است.-->
          <!--                        سوالات‌ آن بر مبنای مشاهده‌ نشانه‌های متداول افسردگی است. یه یاد داشته باشید این تست جایگزینی-->
          <!--                        برای-->
          <!--                        معالجه یا مشاوره تخصصی نیست.-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="textInterpretation mt-10">-->
          <!--                  <v-row>-->
          <!--                    <v-col cols="12" lg="9 " class="" style="margin: 20px auto 0px auto">-->
          <!--                      <div class="textInterpretationTitle">-->
          <!--                        {{ titleInterpretation }}-->
          <!--                      </div>-->
          <!--                      &lt;!&ndash;            <div class="shortInterpretation">&ndash;&gt;-->
          <!--                      &lt;!&ndash;              امتیاز کسب شده توسط شما در این آزمون در محدوده نرمال و طبیعی است. بر اساس این نمره به نظر می‌رسد خوشبختانه شما به افسردگی مبتلا نیستید  و یا این که در آستانه ابتلا به افسردگی قرار دارید؛ پس بهتر است علائم آن مانند احساس ناراحتی طولانی مدت و بدون دلیل را جدی بگیرید.&ndash;&gt;-->
          <!--                      &lt;!&ndash;            </div>&ndash;&gt;-->
          <!--                      <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">-->
          <!--                        <div class="shortInterpretation">-->
          <!--                          <div class="titleShortInterpretation">-->
          <!--                            &lt;!&ndash;                  ناراحتی و غم&ndash;&gt;-->
          <!--                          </div>-->
          <!--                          <div v-html="item.report.description_for_client_fa"></div>-->
          <!--                          &lt;!&ndash;                <div class="hintShortInterpretation">&ndash;&gt;-->
          <!--                          &lt;!&ndash;                                    احتمالا آن‌چنان که انتظار دارید از زندگی لذت نمی‌برید و گاهی احساس می‌کنید که رنج‌های زندگی بیش از شادی‌های آن است.&ndash;&gt;-->
          <!--                          &lt;!&ndash;                </div>&ndash;&gt;-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </v-col>-->
          <!--                  </v-row>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </ConvertToPdf>-->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {VueSvgGauge} from 'vue-svg-gauge'
/*eslint-disable*/
export default {
  components: { VueSvgGauge},
  props: {
    maxScore: Number,
    score: Number,
    report: Object,
    titleInterpretation: String,
    gague_color: String,
    emoji: String,
  },
  data() {
    return {
      gender: 'other',
      networks: [
        // {
        //   title: 'email',
        //   text: '',
        //   icon: 'mdi-email',
        //   url: '',
        //   description: 'من در آزمون معتبر افسردگی بک شرکت کردم و این شد نتیجه‌ش.\n' +
        //       'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
        //   quote: 'quote',
        //   hashtags: 'hashtags',
        //   color: 'blue white--text'
        // },
        {
          title: 'facebook',
          text: '',
          icon: 'mdi-facebook',
          url: '',
          description: 'description',
          quote: 'من در آزمون معتبر افسردگی بک شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          hashtags: 'simiaroom',
          color: 'blue white--text'
        },
        // {
        //   title: 'facebook',
        //   icon: 'mdi-facebook-messenger',
        //   url: '',
        //   description: 'من در آزمون معتبر افسردگی بک شرکت کردم و این شد نتیجه‌ش.\n' +
        //       'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
        //   text: 'من در آزمون معتبر افسردگی بک شرکت کردم و این شد نتیجه‌ش.\n' +
        //       'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
        //   hashtags: 'simiaroom',
        //   color: 'blue white--text'
        // },
        {
          title: 'twitter',
          text: 'من در آزمون معتبر افسردگی بک شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          icon: 'mdi-twitter',
          url: '',
          description: 'description',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'blue white--text lighten-1'
        },
        {
          title: 'telegram',
          text: '',
          icon: 'mdi-telegram',
          url: '',
          description: 'من در آزمون معتبر افسردگی بک شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'blue white--text darken-1'
        },
        {
          title: 'whatsapp',
          text: '',
          icon: 'mdi-whatsapp',
          url: '',
          description: 'من در آزمون معتبر افسردگی بک شرکت کردم و این شد نتیجه‌ش.\n' +
              'اگه تو هم مثل من دوست داری وضعیت خودت رو بدونی، همین حالا در این آزمون شرکت کن (کمتر از ۵ دقیقه) ',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'green white--text'
        },
      ],

    }
  },
  methods: {
    needToDeActive(min, max) {
      if (this.score >= min && this.score <= max) {
        return true
      }
      return false
    }
  },
  computed: {
    interpretationLink() {
      if (this.score >= 0 && this.score <= 13) {
        return "https://simiaroom.com/wp-content/beck/BDI%20R1%20(1).pdf"
      } else if (this.score >= 14 && this.score <= 19) {
        return "https://simiaroom.com/wp-content/beck/BDI%20R2.pdf"
      } else if (this.score >= 20 && this.score <= 28) {
        return "https://simiaroom.com/wp-content/beck/BDI%20R3.pdf"
      } else {
        return "https://simiaroom.com/wp-content/beck/BDI%20R4.pdf"
      }
    },
    girls() {
      return [
        {
          img: require('../../../assets/tests/AtyAssets/SVG/F1g.svg'),
          min: 0,
          max: 13,
          title: 'عدم افسردگی'
        },
        {
          img: require('../../../assets/tests/AtyAssets/SVG/F2g.svg'),
          min: 14,
          max: 19,
          title: 'افسردگی خفیف'
        }
        , {
          img: require('../../../assets/tests/AtyAssets/SVG/F3g.svg'),
          min: 20,
          max: 28,
          title: 'افسردگی متوسط'
        }
        , {
          img: require('../../../assets/tests/AtyAssets/SVG/F4g.svg'),
          min: 28,
          max: 63,
          title: 'افسردگی شدید'
        },
      ]
    },
    boys() {
      return [
        {
          img: require('../../../assets/tests/AtyAssets/SVG/F1b.svg'),
          min: 0,
          max: 13,
          title: 'عدم افسردگی'
        },
        {
          img: require('../../../assets/tests/AtyAssets/SVG/F2b.svg'),
          min: 14,
          max: 19,
          title: 'افسردگی خفیف'
        }
        , {
          img: require('../../../assets/tests/AtyAssets/SVG/F3b.svg'),
          min: 20,
          max: 28,
          title: 'افسردگی متوسط'
        }
        , {
          img: require('../../../assets/tests/AtyAssets/SVG/F4b.svg'),
          min: 28,
          max: 63,
          title: 'افسردگی شدید'
        },
      ]
    },
    others() {
      return [
        {
          img: require('../../../assets/tests/AtyAssets/SVG/Other1.svg'),
          min: 0,
          max: 13,
          title: 'عدم افسردگی'
        },
        {
          img: require('../../../assets/tests/AtyAssets/SVG/Other2.svg'),
          min: 14,
          max: 19,
          title: 'افسردگی خفیف'
        }
        , {
          img: require('../../../assets/tests/AtyAssets/SVG/Other3.svg'),
          min: 20,
          max: 28,
          title: 'افسردگی متوسط'
        }
        , {
          img: require('../../../assets/tests/AtyAssets/SVG/Other4.svg'),
          min: 29,
          max: 63,
          title: 'افسردگی شدید'
        },
      ]
    },
    renderEmojis() {
      switch (this.gender) {
        case "man":
          return this.boys
        case 'woman':
          return this.girls
        default:
          return this.others
      }
    },
    slideHandler() {
      if (this.score >= 0 && this.score <= 13) {
        return '1'
      } else if (this.score >= 14 && this.score <= 19) {
        return '2'
      } else if (this.score >= 20 && this.score <= 28) {
        return '3'
      } else {
        return '4'
      }
    }

  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {

}

.textInterpretationTitle {
  font-size: 18pt;
}


.singleInterpretation {
  margin-top: 40px;
}

.emojiTitle {
  font-size: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}


.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}


.emojiArea {
  /*margin: 20px auto ;*/
}

.shareBtn {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  width: 210px !important;
  height: 54px !important;
  margin: 0 0 20px;
  padding: 7px 44px 10px;
  border-radius: 10px;
  border: solid 2px #4576d8;
}

a {
  text-decoration: none;
}

.deActivePicture {
  filter: grayscale(1);
}

</style>