  <template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center" :class="$vuetify.breakpoint.mdAndUp && 'pa-10'" style="padding-bottom: -50px !important;">
        <div class="gauge ">
          <vue-apex-charts v-if="zoneName" type="radar" :height="$vuetify.breakpoint.mdAndUp ? 600   : 400"
                           :options="chartOptions" :series="series"></vue-apex-charts>
        </div>

        <div class="hintArea ">
          <div class="d-flex justify-space-between  ">
            <div class="">
              <v-icon>mdi-alert</v-icon>
            </div>
            <div class=" pr-4">
              این تست جهت بررسی وضعیت رابطه عاطفی شما بوده و سنجه مناسبی تشخیص نهایی وجود مشکل در رابطه شما نیست.
            </div>
          </div>
        </div>
        <div class="textInterpretation ">
          <v-row>
            <v-col cols="12" lg="9 " class="" style="margin: 0px auto">
              <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">
                <div class="shortInterpretation">
                  <div class="titleShortInterpretation">
                    <!--                  ناراحتی و غم-->
                  </div>
                  <div :id="item.id" v-html="item.report.description_for_client_fa"></div>

                </div>
              </div>
            </v-col>
          </v-row>
          <v-btn color="#46b1a1" class="white--text mt-4" elevation="0" large @click="registerOrTestPanel">
            مقایسه نتیجه تست
            <v-icon class="mr-3">mdi-chart-bar</v-icon>
          </v-btn>

        </div>
      </div>

    </div>
    <br>
    <br>
    <PriceBoxBeck class="PriceBoxBeck" v-if="score > 0 && score >= 13"/>
  </div>
</template>

<script>
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    report: Object,
  },
  components: {
    PriceBoxBeck,
    VueApexCharts
  },
  data() {
    return {

      zoneName: [],
      zonesScore: [],

    }
  },
  created() {
    this.prepareChartInfo();
    // console.log(this.chartOptions)
  },
  methods: {
    registerOrTestPanel(){
      this.$emit('registerOrTestPanel')
    },
    prepareChartInfo() {
      this.report.result.forEach(item => {
        if (item.name != 'Main Zone' && item.name) {
          this.chartOptions.xaxis.categories.push(item.name)
          this.zonesScore.push(item.score.sum)
        }
      })
    }
  },
  computed: {
    series() {
      return [{
        name: '',
        data: this.zonesScore,
      }]
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 5,
            left: 5,
            top: 5
          }
        },
        title: {},
        stroke: {
          width: 4
        },
        fill: {
          opacity: 0.2
        },
        markers: {
          size: 4
        },
        xaxis: {
          labels: {
            show: true,
            // rotate: -35,
            // rotateAlways: true,
            hideOverlappingLabels: false,
            // showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '16px',
              fontFamily: 'inherit',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
              padding: '100px',

            },
            offsetX: 0,
            offsetY: 0,

          },
          categories: [],
        },
        yaxis: {
          min: 0,
          max: 130,
          forceNiceScale: true,
          decimalsInFloat: false,
        }
      }
    },
    score() {
      return this.report.result[0].score.sum;
    },
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
    font-size: 10pt !important;
    margin-top: -100px !important;
  }

  .gauge {
    width: 100% !important;
    text-align: center !important;
    padding-left: 10px;
  }
}

@media print {
  .contentArea {
    width: 10% !important;
    margin: 0px auto;
  }
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.charting {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  /*padding: 40px;*/
}

.gauge {
  width: 100%;
  margin: 50px auto -20px auto;
  position: relative;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  margin: -100px auto 0px auto;
  padding: 20px;
  text-align: center;
  line-height: 2em;
}
.textInterpretation{
  padding-bottom: 0px !important;
}
.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>