<template>
  <div v-if="loadSection">
    <h3 class="titleCollege text-center">
      تفسیر پیشرفته
    </h3>
    <p class="text-center mt-2">
      با کمک مشاوران سیمیاروم می‌توانید تفسیر دقیق و کامل تری از تست خود داشته باشید.
    </p>
    <div class="actionCollage" @click="sendRequestInterpretation">
      درخواست تفسیر پیشرفته
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {parsePhoneNumberFromString} from "libphonenumber-js";

export default {
  data() {
    return {
      loadSection: false,
    }
  },
  computed: {
    ...mapGetters({
      'isLoggedin': 'getIsLoggedIn',
      'profile': 'getProfile',
    }),
  },
  mounted() {
    console.log('here')
    if (this.isLoggedin) {
      this.validatePhoneNumber()
    }
  },
  methods: {
    sendRequestInterpretation() {
      console.log(window.location.href)
    },
    validatePhoneNumber() {
      let phone = this.profile.cel.replaceAll(' ', '')
      if (phone) {
        const parsedNumber = parsePhoneNumberFromString(phone);
        if (parsedNumber.country != 'IR') {
          this.loadSection = true
        }
      }
    }
  },
  watch: {
    profile() {
      this.validatePhoneNumber()
    }
  }
}
</script>

<style scoped>
.titleCollege {
  font-size: 25px;
  font-weight: bolder;
  color: #39B787;
  margin-bottom: 10px;
}

.actionCollage {
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #39B787;
  width: 200px;
  font-size: 13px;
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  margin: 30px auto 0px auto;
  cursor: pointer;
  /*margin-right: 18%;*/
}

</style>