<template>
  <v-window v-model="window" :reverse="this.$store.getters.isRTL" touchless>
    <v-window-item :value="1">
      <div class="text-center" style="color: #574596">
          <span v-if="from != 'PSYTEST'">
            {{ $t('onBoarding.wizardLogin[1]') }}
          </span>
        <span v-else>
            برای دریافت تفسیر تست خود با حساب کاربری خود وارد شوید یا ثبت‌نام کنید
          </span>
      </div>
      <v-card-text>
        <v-form class="pa-0" ref="loginForm" @keyup.native.enter="login">
          <v-text-field
              :loader-height="0"
              v-model="mutableEmail"
              type="text"
              name="input-10-1"
              :label="$t('emailLabel')"
              required
              :rules="emailRules"
              class="mb-0"
              prepend-inner-icon="far fa-envelope"
          >
          </v-text-field>
          <v-text-field
              :loader-height="0"
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-inner-icon="mdi-lock-outline"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('passwordLabel')"
              class="input-group--focused mb-0"
              @click:append="showPassword = !showPassword"
              required
              :rules="passwordRules"
          >
          </v-text-field>


          <div style="width: 100%; text-align: center;margin: 0 auto;" class="mt-3">
            <v-btn
                @click="login"
                :disabled="loginDisabled"
                block
                color="#3CBE8B"
                class="white--text"
                height="50"
                style="border-radius:10px;"
                elevation="0"
                :loading="loginDisabled">
              <span style="font-size: 1.1em;">
                {{ $t('loginLabel') }}
              </span>
              <span style="font-size: 10px" class="mt-1">
                ({{ $t('signupComponent.acceptTerms') }})
              </span>
            </v-btn>
          </div>
        </v-form>
        <div class="text-center mt-3">
          <a class="mt-3" style="color: #3CBE8B!important;"
             @click="window=2">{{ $t('loginComponent.resetPassword') }}</a>
          <br>
          <v-btn text color="#3CBE8B" class="mt-4" @click="changeParentWindow(2)" v-if="showRegisterLabel">
            حساب کاربری ندارید؟
          </v-btn>

        </div>
      </v-card-text>
      <v-card-text class="text-center pt-0 pb-4">
      </v-card-text>
    </v-window-item>
    <v-window-item :value="2">
      <v-card-title class="justify-center">
        <span style="color: #574596">{{ $t('accountRecovery') }}</span>
      </v-card-title>
      <v-card-subtitle class="justify-center" style="color: #574566">
        {{ $t('accountRecoveryCaption') }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="resetPassword">
          <v-text-field
              :loader-height="0"
              @keydown.enter.prevent='resetPassword'
              v-model="emailForResetPassword"
              type="text"
              :label="$t('emailLabel')"
              :rules="emailRules"
              prepend-inner-icon="far fa-envelope"
              @submit.stop="resetPassword"
          >
          </v-text-field>
          <v-card-text v-show="checkEmail" class="green--text text-center">{{ $t('loginComponent.checkEmail') }}
          </v-card-text>
          <v-btn
              color="#3CBE8B"
              class="white--text mt-5"
              height="50"
              style="border-radius:10px;"
              elevation="0"
              @click="resetPassword" :disabled="loginDisabled" block>
            <span>{{ $t('loginComponent.forgetPassword.action') }}</span>
          </v-btn>
          <div class="justify-center mt-4 text-center">
            {{ $t('loginComponent.forgetPassword.loginLabel') }}
            <a @click="window=1" style="color: #3CBE8B!important;">
              {{ $t('loginComponent.forgetPassword.loginAction') }}
            </a>
          </div>
        </v-form>
      </v-card-text>
    </v-window-item>
  </v-window>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import EventsMixins from "@/mixins/EventsMixins";

export default {
  mixins: [EventsMixins],
  data() {
    return {
      showPassword: false,
      // email: "",
      mutableEmail: '',
      emailForResetPassword: '',
      checkEmail: false,
      // emailRules: [],
      password: "",
      errorMessages: '',
      name: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      formHasErrors: false,
      window: 1,
      loginErr: {
        show: false,
        msg: '',
      },
      loginDisabled: false,
      registerDisabled: false
    }
  },
  props: {
    from: {
      default: null,
    },
    GALogin: {
      default: 'ga_login'
    },
    preorderLogin: {
      default: false,
    },
    subtitle: {
      default: 'برای استفاده از پنل کاربری لطفا وارد شوید'
    },
    email: String,
    showRegisterLabel: {
      default: false
    }
  },
  watch: {
    name() {
      this.errorMessages = ''
    },
  },
  computed: {},
  created() {
    this.$store.commit('setLoading', false);
    this.mutableEmail = this.email;
  },
  mounted() {
    // EventBus.$emit('setLoading', true);
  },
  methods: {
    addressCheck() {
      this.errorMessages = this.address && !this.name
          ? 'Hey! I\'m required'
          : ''

      return true
    },
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    resetPassword() {
      if (this.$refs.resetPassword.validate()) {
        this.$emitEvent('setLoading', true);
        this.requesting('auth', 'forgetPassword', '', {
          email: this.emailForResetPassword
        }).then((data) => {
          if (data.type === 'SUCCESS') {
            // this.checkEmail = true;
            this.$emitEvent('notify', {color: 'green', title: this.$t('sentEmailLink'), id: 'emailNotify'})
            setTimeout(() => {
              this.window = 1
            }, 500)
          } else {
            this.checkEmail = false;
          }
        }).finally(() => {
          this.$emitEvent('setLoading', false);
        })
      }
    },
    login() {
      let user = {
        email: this.mutableEmail,
        password: this.password
      };
      if (this.from != null) {
        Object.assign(user, {state: this.from})
      }

      if (this.$refs.loginForm.validate()) {
        this.loginDisabled = true;
        this.$emitEvent('setLoading', true);
        this.requesting('auth', "login", '', user).then((resp) => {
          this.requesting('auth', 'getProfile').then(() => {
            //WebEngage
            this.WebengageSetUserLoggedIn(this.$store.getters.getUserInfo.id)

            this.$emitEvent('userLoggedIn');
            this.$emit('notify', {color: 'green', title: this.$t('Notify.loginSuccess'), id: this.GALogin});
            this.$emit('userLoggedIn');
          }).finally(() => {
            this.loginDisabled = false;
          })
        }).catch((err) => {
          this.loginDisabled = false;
          // console.log('err', err.response.data);
          // if (err.response.data.statusCode === 401 || err.response.data.status === 400) {
          //     this.loginErr = {
          //         show: true,
          //         msg: 'نام کاربری یا کلمه عبور اشتباه است',
          //     }
          // } else if (500 <= err.response.data.statusCode < 600) {
          //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
          // }
        }).finally(() => {
          this.$emitEvent('setLoading', false);
        });
      }
    },
    changeParentWindow(value) {
      this.$emit('changeWindow', value);
    }
  }
}
</script>

<style scoped>

</style>