<template>
  <div class="suggestTherapist">
    <div class="titleArea">
      متخصصان حوزه افسردگی سیمیاروم
    </div>
    <div class="descArea mt-2">
      هر روان‌درمانگر متخصص و باتجربه‌ای می‌تواند به شما کمک کند، اما بعضی از آن‌ها به صورت تخصصی روی موضوع افسردگی
      تجربه دارند
      <br>
      و بهترین گزینه برای شما هستند.
    </div>
    <div class="therapists  mt-10">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-row>
          <v-col cols="12" lg="3" md="6">
            <a target="_blank" href="https://simiaroom.com/counselor/%D8%AF%DA%A9%D8%AA%D8%B1-%D8%B9%D8%A8%D8%A7%D8%B3-%D8%B1%D9%85%D8%B6%D8%A7%D9%86%DB%8C-%D9%81%D8%B1%D8%A7%D9%86%DB%8C/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/ramezani.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر عباس رمضانی فرانی</div>
              </div>
            </a>
          </v-col>
          <v-col cols="12" lg="3" md="6">
            <a target="_blank" href="https://simiaroom.com/counselor/ilkhani/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/ilkhani.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر ملیحه ایلخانی</div>
              </div>
            </a>
          </v-col>
          <v-col cols="12" lg="3" md="6">
            <a target="_blank" href="https://simiaroom.com/counselor/%D8%AF%DA%A9%D8%AA%D8%B1-%D8%B1%D8%B6%D8%A7-%D9%85%D9%88%D9%84%D9%88%D8%AF%DB%8C/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/molodi.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر رضا مولودی</div>
              </div>
            </a>
          </v-col>
          <v-col cols="12" lg="3" md="6">
            <a target="_blank" href="https://simiaroom.com/counselor/%D8%AF%DA%A9%D8%AA%D8%B1-%D9%85%D9%87%D8%B1%D9%86%D9%88%D8%B4-%D8%A7%D8%AB%D8%A8%D8%A7%D8%AA%DB%8C/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/esbati.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر مهرنوش اثباتی</div>
              </div>
            </a>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <hooper
            :rtl="true"
            :itemsToShow="1"
            :autoPlay="true"
            :playSpeed="5000"
            style="min-height:400px;"
            :infiniteScroll="true"
            :wheel-control="false"
        >
          <slide>
            <a target="_blank" href="https://simiaroom.com/counselor/%D8%AF%DA%A9%D8%AA%D8%B1-%D8%B9%D8%A8%D8%A7%D8%B3-%D8%B1%D9%85%D8%B6%D8%A7%D9%86%DB%8C-%D9%81%D8%B1%D8%A7%D9%86%DB%8C/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/ramezani.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر عباس رمضانی فرانی</div>
              </div>
            </a>
          </slide>
          <slide>
            <a target="_blank" href="https://simiaroom.com/counselor/ilkhani/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/ilkhani.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر ملیحه ایلخانی</div>
              </div>
            </a>
          </slide>
          <slide>
            <a target="_blank" href="https://simiaroom.com/counselor/%D8%AF%DA%A9%D8%AA%D8%B1-%D8%B1%D8%B6%D8%A7-%D9%85%D9%88%D9%84%D9%88%D8%AF%DB%8C/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/molodi.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر رضا مولودی</div>
              </div>
            </a>
          </slide>
          <slide>
            <a target="_blank" href="https://simiaroom.com/counselor/%D8%AF%DA%A9%D8%AA%D8%B1-%D9%85%D9%87%D8%B1%D9%86%D9%88%D8%B4-%D8%A7%D8%AB%D8%A8%D8%A7%D8%AA%DB%8C/">
              <div class="singleTherapist">
                <div class="imageArea">
                  <img src="../../../assets/tests/threapists/esbati.jpg" width="100%" alt="">
                </div>
                <div class="nameArea TherapistInterpretation">دکتر مهرنوش اثباتی</div>
              </div>
            </a>
          </slide>
        </hooper>
      </template>
      <div class="text-center">
        <a target="_blank" href="https://simiaroom.com/pre-session/">
          <v-btn class="mt-5 white--text" elevation="0" color="#46b1a1">
            رزرو وقت مشاوره
          </v-btn>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {Hooper, Slide} from "hooper";

export default {
  components: {
    Hooper,
    Slide,
  }
}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 600px) {
  .suggestTherapist {
    margin-top: 40px !important;
  }

  .imageArea {
    margin: 0 auto;
  }

  .nameArea {
    text-align: center;
  }
}

.suggestTherapist {
  margin-top: 40px;
}

.titleArea {
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.descArea {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.imageArea {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: solid 2px #b9b9b9;
}

.nameArea {
  margin-top: 10px;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>